import api from '../../../utilities/api'

export function fetchProfileDetailsReq(id) {
  return api.get(`/organization-coordinators/${id}`)
}

export function updateProfileDetailsReq(id, body) {
  return api.put(`/organization-coordinators/${id}`, body)
}

export function postProfilePhotoReq(file) {
  return api.post(`/users/profile-photo`, file)
}

export function deleteProfilePhotoReq() {
  return api.delete(`/users/profile-photo`)
}

export function updatePasswordReq(body) {
  return api.post(`/users/update-password`, body)
}

export function leaveOrganizationReq(id, body) {
  return api.post(`/organization-coordinators/${id}/leave-organization`, body)
}

export function fetchOrganizationDetailsReq(id) {
  return api.get(`/organizations/${id}`)
}

export function updateOrganizationDetailsReq(id, body) {
  return api.put(`/organizations/${id}`, body)
}
