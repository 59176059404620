import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { isEqual } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import { ModalPropertiesInterface } from '../../../utilities/sharedInterfaces'
import { RootState } from '../../index'
export interface ExtendedModalPropertiesInterface extends ModalPropertiesInterface {
  id: string
}

type openedModalsListInterface = Array<ExtendedModalPropertiesInterface>
interface UniversalModalStateInterface {
  openedModalsList: openedModalsListInterface
}

const initialState: UniversalModalStateInterface = { openedModalsList: [] }

export const universalModalSlice = createSlice({
  name: 'universalModal',
  initialState,
  reducers: {
    openUniversalModal: (state, action: PayloadAction<ModalPropertiesInterface>) => {
      const newList = [...state.openedModalsList]

      newList.push({ ...action.payload, id: uuidv4() })

      state.openedModalsList = newList
    },
    closeUniversalModal: (state, action: PayloadAction<{ id: string }>) => {
      const newList = state.openedModalsList.filter(({ id }) => !isEqual(id, action.payload.id))

      state.openedModalsList = [...newList]
    },
    closeAllOpenModals: () => initialState,
  },
})

export const { openUniversalModal, closeUniversalModal, closeAllOpenModals } = universalModalSlice.actions

export const selectUniversalModalOpenedList = (state: RootState) => state.universalModal.openedModalsList

export default universalModalSlice.reducer
