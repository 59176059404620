import PersonIcon from '@mui/icons-material/Person'
import SettingsIcon from '@mui/icons-material/Settings'
import {
  Avatar,
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import isEmpty from 'lodash/isEmpty'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import Transitions from '../../../../../components/@extended/Transitions'
import MainCard from '../../../../../components/MainCard'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { changeUserState, logOut, selectUser } from '../../../../../store/slices/authSlice/authSlice'
import { serverURL } from '../../../../../utilities/api'

const Profile = () => {
  const theme = useTheme()

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const user = useAppSelector(selectUser)

  const anchorRef = useRef<HTMLButtonElement | null>(null)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    dispatch(changeUserState(user.loggedInUser?.imageToken))
  }, [dispatch, user.loggedInUser?.imageToken])

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return
    }
    setOpen(false)
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75, mr: 2 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? 'grey.300' : 'transparent',
          borderRadius: 1,
          '&:hover': { bgcolor: 'secondary.lighter' },
        }}
        aria-label='open profile'
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
      >
        <Stack direction='row' spacing={1} alignItems='center' sx={{ p: 0.5 }}>
          <Avatar alt='profile user' sx={{ width: 32, height: 32 }}>
            {user?.loggedInUser?.imageToken && (
              <img
                src={`${serverURL}/images/${user?.loggedInUser?.imageToken}`}
                alt='Profile Avatar'
                style={{ objectPosition: 'center', objectFit: 'fill', maxWidth: '40px', maxHeight: '40px' }}
              />
            )}
          </Avatar>
        </Stack>
      </ButtonBase>
      <Popper
        sx={{ zIndex: 9999 }}
        placement='bottom-end'
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type='fade' in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 250,
                  },
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false} content={false}>
                    <CardContent sx={{ px: 4, pt: 2, pb: 1 }}>
                      <Typography variant='h5'>{`${user?.loggedInUser?.name || ''}`}</Typography>
                      <Typography sx={{ color: 'grey.500' }}>{`${user?.loggedInUser?.email || ''}`}</Typography>
                    </CardContent>
                    <Divider />
                    <CardContent sx={{ px: 2, py: 1 }}>
                      <ListItemButton onClick={() => navigate('/settings')}>
                        <ListItemIcon>
                          <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText sx={{ ml: 3 }} primary={t('settings')} />
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => {
                          !isEmpty(user?.loggedInUser) && navigate(`/coordinator/${user.loggedInUser?.id}`)
                        }}
                      >
                        <ListItemIcon>
                          <PersonIcon />
                        </ListItemIcon>
                        <ListItemText sx={{ ml: 3 }} primary={t('profile')} />
                      </ListItemButton>
                    </CardContent>
                    <Divider />
                    <CardContent sx={{ px: 2, pt: 1, pb: 0 }}>
                      <ListItemButton
                        onClick={() =>
                          dispatch(logOut()).then(({ type }) => {
                            if (type.includes('fulfilled')) {
                              navigate('/login')
                              toast.success(t('loggedOut'))
                            }
                          })
                        }
                      >
                        <ListItemText primary={t('logout')} />
                      </ListItemButton>
                    </CardContent>
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  )
}

export default Profile
