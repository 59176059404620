// ==============================|| OVERRIDES - TYPOGRAPHY ||============================== //

export default function Typography() {
  return {
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: 12,
        },
      },
    },
  }
}

export const textDescStyle = {
  color: 'grey',
  textAlign: 'center',
  fontSize: '15px',
  fontWeight: 400,
  lineHeight: '26px' /* 173.333% */,
  letterSpacing: '0.46px',
}
