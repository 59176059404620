import { CssBaseline, StyledEngineProvider } from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { ReactNode, useMemo } from 'react'

import componentsOverride from './overrides'
import Palette from './palette'
import CustomShadows from './shadows'
import Typography from './typography'

// ==============================|| DEFAULT THEME - MAIN  ||============================== //

declare module '@mui/material/styles' {
  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    button: {
      textTransform: string
    }
  }

  interface Palette {
    greyA800: string
    modalBG: string
    whiteGrey: string
    lightGrey: string
    darkGrey: string
    placeholder: string
  }

  interface PaletteColor {
    lighter?: string
    darker?: string
  }

  interface Theme {
    customShadows: {
      button: string
      text: string
      z1: string
    }
  }
}

export default function ThemeCustomization({ children }: { children: ReactNode }) {
  const theme = Palette('light')

  const themeTypography = Typography("'Roboto', sans-serif")
  const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme])

  const themeOptions = useMemo(
    () => ({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1536,
        },
      },
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingTop: 8,
          paddingBottom: 8,
        },
      },
      palette: theme.palette,
      customShadows: themeCustomShadows,
      typography: themeTypography,
    }),
    [theme, themeTypography, themeCustomShadows],
  )

  const themes = createTheme(themeOptions)

  themes.components = componentsOverride(themes)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
