import api from '../../../utilities/api'

export function fetchOurTeamReq(id) {
  return api.get(`/organizations/${id}/team`, {
    headers: {
      'Accept-Language': localStorage.getItem('activeLanguage'),
    },
  })
}

export function inviteCoordinatorReq(body) {
  return api.post('/organization-coordinators/invite', body)
}

export function deleteCoordinatorReq(id) {
  return api.delete(`/organization-coordinators/${id}`)
}
