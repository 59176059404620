import { FC, ReactElement, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

// ==============================|| NAVIGATION - SCROLL TO TOP ||============================== //

const ScrollTop: FC<{ children: ReactElement }> = ({ children }) => {
  const location = useLocation()
  const { pathname } = location

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }, 0)
  }, [pathname])

  return children || null
}

export default ScrollTop
