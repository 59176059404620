import { Buffer } from 'buffer'

import api from '../../../utilities/api'

export function logInReq({ email, password }) {
  const authToken = 'Basic ' + Buffer.from(`${email}:${password}`).toString('base64')
  const header = {
    headers: {
      Authorization: authToken,
      'Source-Device': 'web',
    },
  }
  return api.post('/users/login', {}, header)
}

export function checkIfLoggedInReq() {
  return api.post(
    '/users/login',
    {},
    {
      headers: {
        noToast: true,
      },
    },
  )
}

export function logOutReq() {
  return api.post('/users/logout', {})
}

export function registerReq(body) {
  const header = {
    headers: {
      'Source-Device': 'web',
    },
  }
  return api.post('/organizations', body, header)
}

export function confirmRegisterReq(token) {
  return api.get(`/users/registration-confirm?token=${token}`)
}

export function confirmOrganizationVerificationReq(token) {
  return api.post(`/organizations/email-confirmed`, { emailVerificationToken: token })
}

export function restoreAccountReq(token) {
  return api.get(`/users/restore?token=${token}`)
}

export function registerCoordinatorReq(body) {
  return api.post('/organization-coordinators', body)
}
