/* eslint-disable max-len */
export const pl = {
  volunteerManagementSystem: 'System zarządzania wolontariatem',
  coordinator: 'Koordynator',
  admin: 'Admin',
  home: 'Strona główna',
  backToMainView: 'Powrót do strony głównej',
  login: 'Zaloguj się',
  signIn: 'Zaloguj się',
  logout: 'Wyloguj się',
  profile: 'Profil',
  submit: 'Wyślij',
  add: 'Dodaj',
  save: 'Zapisz',
  edit: 'Edycja',
  cancel: 'Anuluj',
  alreadyhaveAccount: 'Masz już konto',
  registerFoundation: 'Zarejestruj fundację',
  registerFoundationDesc:
    'Witaj w Hand 4 You! Cieszymy się, że mamy Cię na pokładzie. Rejestrując się u nas, robisz pierwszy krok w kierunku pozytywnych zmian. Wypełnij poniższe dane, aby stać się częścią naszej społeczności, łączącej fundacje i wolontariuszy.',
  email: 'Adres Email',
  password: 'Hasło',
  repeatPassword: 'Powtórz hasło',
  firstname: 'Imię',
  lastname: 'Nazwisko',
  firstnameLastname: 'Imię i nazwisko',
  foundationName: 'Nazwa organizacji',
  country: 'Kraj',
  postCode: 'Kod pocztowy',
  city: 'Miejscowość',
  street: 'Ulica',
  houseNumber: 'Nr budynku',
  apartmentNumber: 'Nr mieszkania',
  addressDescription: 'Szczegóły adresu',
  currentVolunteersNum: 'Liczba wolontariuszy',
  currentVolunteersNumPlaceholder: 'Liczba wolontariuszy w organizacji',
  category: 'Kategoria',
  description: 'Opis',
  descriptionPlaceholder: 'Napisz coś o swoim wydarzeniu',
  phone: 'Telefon',
  region: 'Województwo',
  county: 'Powiat',
  generalInformation: 'Informacje ogólne',
  contactInformation: 'Informacje kontaktowe',
  cover: 'Obraz tła',
  coverDesc: 'Dodaj plik (.jpg, .jpeg, .png, .gif - max 4Mb) lub wybierz coś z dostępnych wzorów',
  createAccount: 'Utwórz konto',
  registerAgreement:
    'Kontynuując, zgadzasz się, abyśmy utworzyli dla Ciebie konto (o ile nie zostało już utworzone) i akceptujesz nasze Warunki i Zasady oraz Politykę prywatności.',
  signUp: 'Zarejestruj się',
  changePassword: 'Zmień hasło',
  required: 'To pole jest wymagane',
  unsupported: 'Nieobsługiwany',
  notFound: 'Nie znaleziono',
  register: 'Załóż konto',
  forgotPassword: 'Zapomniałeś hasła',
  forgotPasswordDesc: 'Nie martw się, wyślemy Ci instrukcje resetowania.',
  backToLogIn: 'Powrót do logowania',
  enterLogin: 'Wprowadź login',
  enterPassword: 'Wprowadź hasło',
  confirmPassword: 'Potwierdź hasło',
  enterEmail: 'Wprowadź Email',
  sendEmail: 'Wyślij email',
  confirmSave: 'Zatwierdź',
  passwordChanged: 'Poprawnie zmieniono hasło',
  name: 'Nazwa',
  address: 'Adres',
  contactUs: 'Kontakt',
  volunteeringAtYourFingertips: 'Wolontariat na wyciągnięcie ręki',
  volunteeringAtYourFingertipsDesc:
    'Hand for U to aplikacja webowa i mobilna, która pomaga fundacjom charytatywnym, wolontariuszom i osobom potrzebującym. Czy chcesz rozwijać swoją fundację? Dołącz do nas!',
  systemInfo: 'Testujemy aplikację i wciąż się rozwijamy. Czy chcesz rozwijać Hand for U z nami? Masz pytania?',
  writeToUs: 'Napisz do nas',
  anyKindOfHelp: 'Ty wybierasz jak pomagasz',
  anyKindOfHelpDesc: 'Z nami stworzysz dowolne kategorie, w których chcesz pomagać!',
  healthcare: 'Ochrona zdrowia',
  nature: 'Natura',
  charity: 'Dobroczynność',
  elderlyPeople: 'Osoby starsze',
  humanRights: 'Prawa człowieka',
  socialAssistance: 'Pomoc społeczna',
  animals: 'Zwierzęta',
  education: 'Edukacja',
  ecology: 'Ekologia',
  helpForUkraine: 'Pomoc Ukrainie',
  sportsAndTourism: 'Sport i turystyka',
  volunteeringMadeEasier: 'Wolontariat – łatwiej',
  volunteeringMadeEasierDesc:
    'Web panel zapewnia fundacjom platformę, wsparcie i narzędzia do zarządzania pracą wolontariuszy, a także do publikowania informacji w aplikacji mobilnej.',
  createEvents: 'Twórz wydarzenia',
  createEventsDesc:
    'Naszą misją jest wspieranie świata, w którym życzliwość i współczucie nie znają granic. Nasza platforma umożliwia fundacjom i wolontariuszom łączenie się podczas wydarzeń, które mogą być dodane przez fundacje poprzez panel webowy.',
  empowerVolunteers: 'Daj wolontariuszom siłę do działania',
  empowerVolunteersDesc:
    'Aplikacja mobilna daje wolontariuszom szansę na odgrywanie aktywnej roli w pomaganiu innym. Wolontariusze mogą publikować oferty pomoc i reagować na prośby pomocy publikowane przez osoby potrzebujące.',
  adminDetails: 'Dane administratora',
  foundationDetails: 'Dane organizacji',
  helpLocation: 'Obszar pomocy',
  chooseHelpLocation: 'Wybierz obszar pomocy',
  actions: 'Akcje',
  foundationPlaceholder: 'Nazwa organizacji',
  countryPlaceholder: 'np. Polska',
  postCodePlaceholder: 'np. 21-345',
  cityPlaceholder: 'np. Warszawa',
  streetPlaceholder: 'Ulica',
  categoryPlaceholder: 'Wybierz kategorię',
  emailPlaceholder: 'np. johndoe@gmail.com',
  phonePlaceholder: 'nr kier. +48 lub +380 i 9 cyfr, np. +48111222333',
  helloAgain: 'Witaj ponownie',
  loginDesc:
    'Uzyskaj dostęp do swojego konta i nadal wywieraj pozytywny wpływ. Wprowadź swoje dane poniżej, aby się zalogować.',
  dontHaveAnAccount: 'Nie posiadasz jeszcze konta',
  registerHere: 'Zarejestruj się tutaj',
  events: 'Wydarzenia',
  eventsDesc:
    'Witamy na Twoich wydarzeniach, podczas których Twoja wizja pozytywnych zmian ożywa. Tutaj znajdziesz listę wydarzeń swojej fundacji.',
  noEvents: 'Nie masz jeszcze żadnych wydarzeń',
  noEventsDesc:
    'Każde wydarzenie, które tworzysz, jest zaproszeniem dla innych, aby dołączyli do ciebie w twojej misji. Utwórz swoje pierwsze wydarzenie już teraz!',
  createEvent: 'Utwórz wydarzenie',
  createEventDesc: 'Gotowy do stworzenia nowego wydarzenia? Wypełnij poniższe pola i wybierz lokalizację!',
  creator: 'Twórca',
  eventStart: 'Początek wydarzenia',
  eventStatus: 'Status wydarzenia',
  helpCategory: 'Kategoria pomocy',
  created: 'Utworzono',
  max: 'Max',
  volunteersNumber: 'Ilu wolontariuszy potrzebujesz?',
  startDate: 'Data startu',
  endDate: 'Data zakończenia',
  content: 'Treść',
  title: 'Tytuł',
  titlePlaceholder: 'Niesamowity tytuł',
  info: 'Informacje',
  volunteers: 'Wolontariusze',
  ourTeam: 'Nasz zespół',
  clear: 'Usuń',
  addMore: 'Dodaj więcej',
  continue: 'Kontynuuj',
  back: 'Wstecz',
  setPassword: 'Ustaw hasło',
  setNewPassword: 'Podaj nowe hasło',
  setNewPasswordDesc: 'Utwórz nowe hasło i kontynuuj swoją podróż do wywierania pozytywnego wpływu dzięki Hand 4 You.',
  ourTeamEmptyDesc: 'Nie masz jeszcze żadnych członków. Dodaj kogoś!',
  nothingIsHere: 'Nic tu nie ma',
  nothingIsHereTeamDesc: 'Dodaj członków',
  addSomeone: 'Dodaj',
  ourEvents: 'Nasze wydarzenia',
  ourEventsEmptyDesc: 'Tu znajduje się lista waszych wydarzeń',
  nothingIsHereEventsDesc: 'Utwórz swoje pierwsze wydarzenie',
  volunteersEmptyDesc: 'Nie macie jeszcze żadnych wolontariuszy. Dodaj wolontariusza',
  nothingIsHereVolunteersDesc: 'Dodaj wolontariuszy',
  vatNr: 'NIP',
  vatNrPlaceholder: 'Wprowadź NIP organizacji',
  originDate: 'Data założenia',
  originDatePlaceholder: 'Wprowadź datę założenia organizacji',
  website: 'Strona www',
  atLeastOneOption: 'Przynajmniej jedna opcja musi być wybrana',
  registerSucceed: 'Rejestracja przebiegła pomyślnie',
  status: 'Status',
  draft: 'Szkic',
  active: 'Aktywne',
  finished: 'Ukończone',
  location: 'Lokalizacja',
  event: 'Wydarzenie',
  nrOfVolunteersSigned: 'Liczba zgłoszonych wolontariuszy',
  eventCreator: 'Twórca wydarzenia',
  createEventSuccess: 'Gratulacje! Wydarzenie utworzone!',
  createEventError: 'Coś poszło nie tak podczas tworzenia wydarzenia!',
  editEventSuccess: 'Edytowano wydarzenie!',
  editEventError: 'Coś poszło nie tak podczas edycji wydarzenia!',
  seeAll: 'Zobacz wszystkich',
  participants: 'Uczestnicy',
  participantsDesc: 'Lista uczestników, którzy chcą dołączyć do wydarzenia',
  nrParticipantsNeededToFormFullTeam: 'Liczba uczestników potrzebna do utworzenia pełnego zespołu',
  numberOfTeamMembers: 'Liczba członków zespołu',
  inviteCoordinator: 'Zaproś koordynatora',
  avatar: 'Awatar',
  role: 'Rola',
  inviteCoordinatorDesc: 'Wyślij zaproszenie do koordynatora, aby dołączył do zespołu',
  sendInvite: 'Wyślij zaproszenie',
  deleteCoordinator: 'Usuń koordynatora',
  deleteCoordinatorDesc: 'Tej akcji nie można cofnąć.',
  yesDelete: 'Tak, usuń',
  profileCoordinatorDesc: 'Tutaj możesz sprawdzić dane osobowe koordynatora oraz historię jego wydarzeń',
  profileVolunteerDesc: 'Tutaj możesz sprawdzić dane osobowe wolontariusza oraz historię jego wydarzeń',
  personalInfo: 'Informacje osobiste',
  contactInfo: 'Informacje kontaktowe',
  locationInfo: 'Informacje o lokalizacji',
  dateOfBirth: 'Data urodzenia',
  firstAddressLine: 'Pierwsza linia adresu',
  volunteersNeededNum: 'Liczba potrzebnych wolontariuszy',
  accountStatus: 'Status konta',
  aboutMe: 'O mnie',
  languages: 'Języki',
  history: 'Historia',
  reject: 'Odrzuć',
  verify: 'Zweryfikuj',
  daysOfHelp: 'Dni pomocy',
  howDidYouFindAboutUs: 'Jak dowiedziałeś się o nas?',
  haveYouBeenAVolunteerBefore: 'Czy byłeś już wolontariuszem?',
  other: 'Inne',
  rejectVolunteer: 'Czy chcesz odrzucić tego wolontariusza?',
  rejectVolunteerDesc: 'Tej akcji nie można cofnąć.',
  verifyVolunteer: 'Czy chcesz zweryfikować tego wolontariusza?',
  verifyVolunteerDesc: 'Ten wolontariusz zostanie przypisany do twojej organizacji.',
  confirm: 'Potwierdź',
  volunteerRejected: 'Wolontariusz odrzucony',
  volunteerVerified: 'Wolontariusz zweryfikowany',
  verifiedUser: 'Zweryfikowany użytkownik',
  rejectedUser: 'Odrzucony użytkownik',
  attachedFiles: 'Załączone pliki',
  deleteVolunteer: 'Usuń wolontariusza',
  fileDownloaded: 'Pobrano plik',
  volunteerDeleted: 'Wolontariusz usunięty',
  areYouSureToDeleteVolunteer: 'Czy na pewno chcesz usunąć wolontariusza',
  settings: 'Ustawienia',
  account: 'Konto',
  notifications: 'Powiadomienia',
  organization: 'Organizacja',
  profileDetails: 'Dane profilu',
  uploadProfilePhoto: 'Prześlij zdjęcie profilowe',
  delete: 'Usuń',
  resetPassword: 'Zresetuj hasło',
  currentPassword: 'Obecne hasło',
  newPassword: 'Nowe hasło',
  newPasswordRepeat: 'Powtórz nowe hasło',
  leaveOrganization: 'Opuść organizację',
  reason: 'Powód',
  total: 'Razem',
  foundProblem: 'Napotkałeś problem',
  reportProblem: 'Zgłoś problem',
  userManual: 'Instrukcja użytkownika',
  newVolunteer: 'Nowy wolontariusz',
  newEventParticipant: 'Nowy uczestnik wydarzenia',
  eventParticipantResignation: 'Rezygnacja uczestnika wydarzenia',
  volunteerLeave: 'Odejście wolontariusza',
  abuse: 'Nadużycia',
  eventEnd: 'Koniec wydarzenia',
  eventCancellation: 'Usunięcie wydarzenia',
  systemNotifications: 'Powiadomienia systemowe',
  newVolunteerDesc: 'Otrzymuj powiadomienia o osobach, które chcą dołączyć do organizacji',
  newEventParticipantDesc: 'Otrzymuj powiadomienia o osobach, które dołączyły do Twojego wydarzenia',
  eventParticipantResignationDesc:
    'Otrzymuj powiadomienia o uczestnikach Twojego wydarzenia, którzy zrezygnowali z udziału w nim',
  volunteerLeaveDesc: 'Otrzymuj powiadomienia o wolontariuszach, którzy wypisali się z organizacji',
  abuseDesc: 'Otrzymuj powiadomienia o osobach, które zostały zgłoszone jako stosujące nadużycia w aplikacji',
  eventEndDesc: 'Otrzymuj powiadomienia o zakończeniu Twojego wydarzenia',
  eventCancellationDesc: 'Otrzymuj powiadomienia o usunięciu Twojego wydarzenia przez administratora',
  systemNotificationsDesc: 'Otrzymuj powiadomienia o zmianach i błędach zachodzących w systemie',
  all: 'Wszystkie',
  coordinators: 'Koordynatorzy',
  system: 'System',
  search: 'Wyszukaj',
  searchPlaceholder: 'Imię, email, itp...',
  date: 'Data',
  logInMobileApp: 'Zaloguj się w mobilnej aplikacji H4U',
  goToMobileApp: 'Przejdź do aplikacji mobilnej',
  editEvent: 'Edytuj wydarzenie',
  editEventDesc: 'Edytuj informacje o swoim wydarzeniu',
  mobileApplication: 'Aplikacja mobilna',
  mobileApplicationDesc:
    'Nasza aplikacja jest przeznaczona dla wolontariuszy i osób potrzebujących. Załóż konto, dołącz do wybranej fundacji i wyciągnij pomocną dłoń jako wolontariusz lub chwyć ją jako osoba potrzebująca.',
  joinEvents: 'Dołącz do wydarzeń',
  publishOffers: 'Publikuj oferty i prośby o pomoc',
  meetPeople: 'Poznaj ludzi ze swojej okolicy',
  interestedInOurProjects: 'Zainteresowały Cię nasze produkty',
  interestedInOurProjectsDesc:
    'Od ponad 20 lat wspieramy firmy i instytucje korzystające z nowoczesnych rozwiązań informatycznych. Dbamy o to, aby technologia faktycznie wspierała działania naszych klientów i zapewniała im realną wartość dodaną.',
  peopleInNeed: 'Osoby potrzebujące',
  peopleInNeedDesc: 'Pomagamy potrzebującym, łącząc ich z wolontariuszami i fundacjami.',
  weAlreadyHave: 'Mamy już',
  members: 'członków',
  futureEvents: 'Przyszłe wydarzenia',
  activeEvents: 'Aktywne wydarzenia',
  finishedEvents: 'Zakończone wydarzenia',
  noParticipantsYet: 'Brak uczestników',
  activationTokenAlreadyUsed: 'Token aktywacyjny został już użyty',
  rating: 'Ocena',
  // API responses:
  errorMessageDefault: 'Błąd. Spróbuj ponownie',
  invalidData: 'Wprowadzono niepoprawne dane',
  success: 'Sukces!',
  createSuccess: 'Utworzono pomyślnie',
  editSuccess: 'Zedytowano pomyślnie',
  deleteSuccess: 'Usunięto pomyślnie',
  loggedIn: 'Zalogowano pomyślnie',
  loggedOut: 'Wylogowano pomyślnie',
  loginSuccessfull: 'Zalogowano pomyślnie',
  loginError: 'Błąd wprowadzonych danych',
  accountExists: 'Konto o podanym adresie email już istnieje!',
  tokenExpired: 'Token nieprawidłowy lub wygasł',
  fileUploaded: 'Plik przesłany',
  fileDeleted: 'Plik usunięty',
  awaitRegisterEmail: 'Sprawdź swoją skrzynkę pocztową w celu potwierdzenia rejestracji',
  accountActivated: 'Twoje konto zostało aktywowane',
  participantsCount: 'Liczba uczestników',
  coordinatorInviteSent: 'Zaproszenie koordynatora zostało wysłane',
  coordinatorDeleted: 'Koordynator usunięty',
  invitationSent: 'Zaproszenie wysłane',
  profileDetailsUpdated: 'Dane profilu zaktualizowane',
  passwordUpdated: 'Hasło zaktualizowane',
  requestSent: 'Prośba została wysłana',
  foundationLeft: 'Organizacja została opuszczona',
  fileError: 'Wystąpił błąd podczas wgrywania pliku',
  forgotPasswordResponse: 'Jeśli konto istnieje, wiadomość e-mail dotycząca resetowania hasła została wysłana',
  editOrganizationSettingsSuccess: 'Ustawienia organizacji zostały zaktualizowane',
  editOrganizationSettingsError: 'Wystąpił błąd podczas aktualizacji ustawień organizacji',
  awaitingVerification: 'Dziękujemy za potwierdzenie maila! Twoje zgłoszenie jest weryfikowane.',
  accountRestored: 'Twoje konto zostało przywrócone',
  // validations:
  requiredField: 'To pole jest wymagane',
  validEmail: 'Nieprawidłowy adres email',
  validPassword: 'Hasło musi zawierać min. 8 znaków, wielką literę, jedną małą, jedną cyfrę i jeden znak specjalny',
  noRows: 'Brak wierszy',
  nameRequired: 'Imię jest wymagane',
  firstnameRequired: 'Imię jest wymagane',
  lastnameRequired: 'Nazwisko jest wymagane',
  emailRequired: 'Adres email jest wymagany',
  loginRequired: 'Login jest wymagany',
  passwordRequired: 'Hasło jest wymagane',
  passwordMatch: 'Hasła się nie zgadzają',
  phoneInvalid: 'Niepoprawny numer telefonu',
  invalidNip: 'Niepoprawny NIP',
  min1Chars: 'Wymagany jest minimum 1 znak',
  min3Chars: 'Wymagane są minimum 3 znaki',
  min5Chars: 'Wymagane jest minimum 5 znaków',
  min200Chars: 'Wymagane jest minimum 200 znaków',
  max5Chars: 'Wymagane jest maksymalnie 5 znaków',
  max8Chars: 'Wymagane jest maksymalnie 8 znaków',
  max20Chars: 'Wymagane jest maksymalnie 20 znaków',
  max50Chars: 'Wymagane jest maksymalnie 50 znaków',
  max250Chars: 'Wymagane jest maksymalnie 250 znaków',
  max1500Chars: 'Wymagane jest maksymalnie 1500 znaków',
  max5000: 'Wymagane jest maksymalnie 5000 znaków',
  phoneNumberNineCharacters: 'Numer telefonu musi mieć co najmniej 9 znaków',
  invalidUrl: 'Niepoprawny adres strony WWW',
  invalidDateFormat: 'Niepoprawny format daty',
  onlyNumbersAllowed: 'Dozwolone są tylko cyfry',
}
