import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { deleteCoordinatorReq, fetchOurTeamReq, inviteCoordinatorReq } from './ourTeamAPI'
import { RootState } from '../../index'

interface OurTeam {
  id: string
  imageToken: string
  name: string
  email: string
  phoneNumber: string
  roles: string[]
  helpLocations: string[]
}

interface OurTeamSliceState {
  ourTeamList: OurTeam[]
}

const initialState: OurTeamSliceState = {
  ourTeamList: [],
}

export const fetchOurTeam = createAsyncThunk('list/fetchOurTeam', async (id: string | null | undefined) => {
  const { data = [] } = await fetchOurTeamReq(id)
  return data.content
})

export const inviteCoordinator = createAsyncThunk('list/inviteCoordinator', async (body: { email: string }) => {
  await inviteCoordinatorReq(body)
})

export const deleteCoordinator = createAsyncThunk('list/deleteCoordinator', async (id: number | string) => {
  await deleteCoordinatorReq(id)
})

export const ourTeamSlice = createSlice({
  name: 'ourTeam',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchOurTeam.fulfilled, (state, action) => {
      state.ourTeamList = action.payload
    })
  },
})

export const selectOurTeamList = (state: RootState) => state.ourTeam.ourTeamList

export default ourTeamSlice.reducer
