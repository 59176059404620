/* eslint-disable no-console */
import axios from 'axios'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'

import { LocalStorageTTL } from './functions'

// TODO: add env vars
export const serverURL = process?.env?.REACT_APP_BASE_URL ?? '/api'
let store

const api = axios.create({
  baseURL: serverURL,
})

function createBaseUrl() {
  const { origin } = window.location
  if (process?.env?.NODE_ENV === 'development') {
    return 'http://192.168.1.22:8080/api'
  }
  return `${origin}/api`
}

export const serverUrlForOtherRequests = createBaseUrl()

export const getHeaders = () => {
  const headers = { headers: {} }
  headers.headers['Accept-Language'] = process?.env?.REACT_APP_LANGUAGE ?? 'pl'
  const token = LocalStorageTTL.get('loginToken')
  if (token) {
    headers.headers['X-Auth-Token'] = token
  }
  return headers
}

export async function errorComposer(error) {
  const response = error.response
  const responseData = response?.data
  const statusCode = error.response?.status
  const statusCodeString = statusCode || 'N/A'
  const errorDate = new Date().toLocaleString()

  const { message } = responseData

  console.group()
  console.log('%cGlobal error handler:', 'color: red')
  console.log(`Error time: ${errorDate}`)
  console.log(`Error status code: ${statusCodeString}`)
  console.log(`Error message: ${message || 'N/A'}`)
  console.log(`Error object: ${error}`)
  console.groupEnd()

  if (!store) {
    store = await import('../routes')
    if (store?.store) {
      store = store.store
    }
  }

  switch (statusCode) {
    case 400:
      // execution context: validation error
      console.error(message)
      if (!isEmpty(responseData.messages)) {
        responseData?.messages?.map(msg => {
          return toast.error(msg)
        })
      }
      break
    case 401:
      // execution context:
      // 1. Wrong login credentials
      // 2. Incorrect token
      if (!isEmpty(responseData.messages)) {
        responseData?.messages?.map(msg => {
          return toast.error(msg)
        })
      }
      // force logout if token was incorrect
      // const hasToken = !!LocalStorageTTL.get('loginToken')
      // if (hasToken) {
      //   logOut({ forceLogOut: true, showSnackbar: false })
      // }
      break
    case 403:
      // execution context: N/A
      console.error('Wystąpił błąd')
      if (!isEmpty(responseData.messages)) {
        responseData?.messages?.map(msg => {
          return toast.error(msg)
        })
      }
      break
    case 404:
      // execution context: resource unobtainable
      if (!isEmpty(responseData.messages)) {
        responseData?.messages?.map(msg => {
          return toast.error(msg)
        })
      }
      break
    case 500:
      // execution context: internal server error
      console.error('Internal server error')
      break
    case 503:
      // execution context: N/A
      console.error(message)
      toast.error(message)
      break
    default:
      console.error('Wystąpił błąd')
      break
  }
}

api.interceptors.response.use(
  response => response,
  error => {
    console.log(error)
    errorComposer(error)
    // need to return Promise.reject(error) because if returned just error, it will execute .then AND .catch functions
    return Promise.reject(error)
  },
)

export default api
