import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit'

import authReducer from './slices/authSlice/authSlice'
import eventsReducer from './slices/eventsSlice/eventsSlice'
import loaderReducer from './slices/loaderSlice/loaderSlice'
import ourTeamReducer from './slices/ourTeamSlice/ourTeamSlice'
import peopleInNeedReducer from './slices/peopleInNeedSlice/peopleInNeedSlice'
import settingsReducer from './slices/settingsSlice/settingsSlice'
import universalModalReducer from './slices/universalModalSlice/universalModalSlice'
import volunteersReducer from './slices/volunteersSlice/volunteersSlice'

export const store = configureStore({
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    login: authReducer,
    universalModal: universalModalReducer,
    loader: loaderReducer,
    peopleInNeed: peopleInNeedReducer,
    volunteers: volunteersReducer,
    ourTeam: ourTeamReducer,
    events: eventsReducer,
    settings: settingsReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
