import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { MutableRefObject } from 'react'
import { AnyObject } from 'yup'

import {
  checkIfLoggedInReq,
  confirmOrganizationVerificationReq,
  confirmRegisterReq,
  logInReq,
  logOutReq,
  registerCoordinatorReq,
  registerReq,
  restoreAccountReq,
} from './authAPI'
import { RegisterCoordinatorInterface, RegisterOrganizationInterface } from '../../../utilities/sharedInterfaces'
import { RootState } from '../../index'

type AuthData = {
  user: Partial<UserInterface>
  authToken: string | null
}

export interface UserInterface {
  code: number
  messages: Array<string>
  pSuccess: boolean
  creator?: {
    creatorId: string | null
    name: string | null
  }
  userRoles?: Array<string>
  loggedInUser?: {
    organizationId: string | null | undefined
    email: string | null | undefined
    id: string | null | undefined
    imageToken: string | null | undefined
    name: string | null | undefined
    roles: Array<string>
  }
}

const initialState: AuthData = {
  user: {},
  authToken: null,
}

export const logIn = createAsyncThunk('login/logIn', async (body: { email: string; password: string }) => {
  const { data } = await logInReq(body)
  return data
})

export const logOut = createAsyncThunk('login/logOut', async () => {
  await logOutReq()
  return {}
})

export const checkIfLoggedIn = createAsyncThunk('login/checkIfLoggedIn', async () => {
  const { data } = await checkIfLoggedInReq()
  return data
})

export const register = createAsyncThunk(
  'organizations',
  async (body: MutableRefObject<RegisterOrganizationInterface> | AnyObject) => {
    const { data } = await registerReq(body)
    return data
  },
)

export const confirmRegister = createAsyncThunk('register/confirmRegister', async (token: string | null) => {
  const { data } = await confirmRegisterReq(token)
  return data
})

export const confirmOrganizationVerification = createAsyncThunk(
  'register/confirmOrganizationVerification',
  async (token: string | null) => {
    const { data } = await confirmOrganizationVerificationReq(token)
    return data
  },
)

export const restoreAccount = createAsyncThunk('register/restoreAccount', async (token: string | null) => {
  const { data } = await restoreAccountReq(token)
  return data
})

export const registerCoordinator = createAsyncThunk(
  'coordinator/registerCoordinator',
  async (body: MutableRefObject<RegisterCoordinatorInterface> | AnyObject) => {
    const { data } = await registerCoordinatorReq(body)
    return data
  },
)

export const authSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetLogin: () => initialState,
    setAuthToken: (state, action) => {
      state.authToken = action.payload
    },
    clearAuthToken: state => {
      state.authToken = null
    },
    changeUserState: (state, action) => {
      if (state?.user?.loggedInUser) {
        state.user = { ...state.user, loggedInUser: { ...state.user.loggedInUser, imageToken: action.payload } }
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(logIn.fulfilled, (state, action) => {
        state.user = action.payload
      })
      .addCase(checkIfLoggedIn.fulfilled, (state, action) => {
        state.user = action.payload
      })
      .addCase(logOut.fulfilled || logIn.rejected, state => {
        if (state.user) {
          state.user = {}
        }
      })
  },
})

export const { resetLogin, setAuthToken, clearAuthToken, changeUserState } = authSlice.actions

export const selectUser = (state: RootState) => state.login.user

export default authSlice.reducer
