import { Box, Link, Typography } from '@mui/material'
import isEmpty from 'lodash/isEmpty'
import { useLocation, useNavigate } from 'react-router-dom'

// import Notification from '../../layout/MainLayout/Header/HeaderContent/Notification'
import Profile from '../../layout/MainLayout/Header/HeaderContent/Profile'
import modules from '../../menu-items/modules'
import { useAppSelector } from '../../store/hooks'
import { selectUser } from '../../store/slices/authSlice/authSlice'
import LanguagePicker from '../LanguagePicker/LanguagePicker'

const logoStyle = {
  width: '100px',
  height: '100px',
  cursor: 'pointer',
}

const headerTextStyle = {
  color: '#787878',
  fontSize: { xs: '10px', sm: '12px', md: '16px', lg: '20px', xl: '24px' },
  fontWeight: '400',
  lineHeight: '150%',
  letterSpacing: '0.15px',
}

function Header() {
  const location = useLocation()
  const navigate = useNavigate()
  const user = useAppSelector(selectUser)

  const menuItems = modules.map(el => {
    return (
      <Link
        key={el.id}
        onClick={() => navigate(el.url || '/')}
        sx={{
          cursor: 'pointer',
          fontSize: { xs: '14px', sm: '16px', md: '18px', lg: '20px', xl: '24px' },
          fontStyle: 'normal',
          fontWeight: 300,
          lineHeight: 'normal',
          color: '#000',
        }}
      >
        {el.title}
      </Link>
    )
  })

  return (
    <Box
      sx={{
        display: 'flex',
        flexShrink: '0',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '104px',
        borderRadius: '16px',
        background: '#FFF',
        boxShadow: '-4px 9px 25px -6px rgba(0, 0, 0, 0.10)',
        margin: { xs: '4px 8px', sm: '12px 20px', md: '20px 20px', lg: '20px 80px', xl: '20px 80px' },
      }}
    >
      <Box
        sx={{
          display: 'inline-flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: { xs: '12px', sm: '14px', md: '31px', lg: '31px', xl: '31px' },
        }}
      >
        <>
          <Box onClick={() => navigate(isEmpty(user) ? '/' : '/overview')}>
            <img src={'/images/logo.png'} alt='Logo' style={logoStyle} />
          </Box>
          {location.pathname === '/' ||
          location.pathname === '/login' ||
          location.pathname === '/register' ||
          location.pathname === '/reset-password' ||
          location.pathname.includes('registration-confirm') ||
          location.pathname.includes('restore-account') ? (
            <Typography sx={headerTextStyle}>Connecting Compassionate Souls</Typography>
          ) : (
            menuItems
          )}
        </>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '12px',
          pr: 1,
        }}
      >
        <LanguagePicker />
        {!isEmpty(user) && (
          <>
            {/* for future */}
            {/* <Notification /> */}
            <Profile />
          </>
        )}
      </Box>
    </Box>
  )
}

export default Header
