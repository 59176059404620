// ==============================|| OVERRIDES - BUTTON ||============================== //

import { useMediaQuery } from '@mui/material'

export default function Button(theme) {
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'))

  const disabledStyle = {
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[200],
    },
  }

  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: matchesXs ? '0.475rem' : '0.875rem',
        },
        contained: {
          ...disabledStyle,
        },
        outlined: {
          ...disabledStyle,
        },
      },
    },
  }
}
