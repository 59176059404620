import { lazy } from 'react'

import Loadable from '../components/Loadable'
import MainLayout from '../layout/MainLayout'

// render - login
const AuthLogin = Loadable(lazy(() => import('../screens/authentication/Login')))
const AuthRegister = Loadable(lazy(() => import('../screens/authentication/Register')))
const NotFound = Loadable(lazy(() => import('../screens/extra-pages/NotFound')))
const ActivateAccount = Loadable(lazy(() => import('../screens/authentication/ActivateAccount')))
const ActivateOrganization = Loadable(lazy(() => import('../screens/authentication/ActivateOrganization')))
const RestoreAccount = Loadable(lazy(() => import('../screens/authentication/RestoreAccount')))
const ResetPassword = Loadable(lazy(() => import('../screens/authentication/ResetPassword')))

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: 'login',
      element: <AuthLogin />,
    },
    {
      path: 'register',
      element: <AuthRegister />,
    },
    {
      path: 'registration-confirm',
      element: <ActivateAccount />,
    },
    {
      path: 'organization-registration-confirm',
      element: <ActivateOrganization />,
    },
    {
      path: 'restore-account',
      element: <RestoreAccount />,
    },
    {
      path: 'reset-password',
      element: <ResetPassword />,
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ],
}

export default LoginRoutes
