/* eslint-disable max-len */
export const uk = {
  volunteerManagementSystem: 'Система управління волонтерами',
  coordinator: 'Координатор',
  admin: 'адмін',
  home: 'додому',
  backToMainView: 'Повернутися на головну сторінку',
  login: 'авторизуватися',
  signIn: 'авторизуватися',
  logout: 'вийти',
  profile: 'Профіль',
  submit: 'Надіслати',
  add: 'додати',
  save: 'зберегти',
  edit: 'редагувати',
  cancel: 'Скасувати',
  alreadyhaveAccount: 'Вже є аккаунт',
  registerFoundation: 'Зареєструвати фонд',
  registerFoundationDesc:
    'Ласкаво просимо до Hand 4 You! Ми раді бачити вас на борту. Реєструючись у нас, ви робите перший крок до позитивних змін. Заповніть дані нижче, щоб стати частиною нашої спільноти, що об’єднує фонди та волонтерів.',
  email: 'Електронна пошта',
  password: 'Пароль',
  repeatPassword: 'Повторіть пароль',
  firstname: "Ім'я",
  lastname: 'Прізвище',
  firstnameLastname: "Ім'я та прізвище",
  foundationName: 'Назва організації',
  country: 'країна',
  postCode: 'ЗІП код',
  street: 'вул',
  houseNumber: '№ будинку',
  apartmentNumber: '№ квартири',
  addressDescription: 'Деталі адреси',
  city: 'Місто',
  currentVolunteersNum: 'Кількість волонтерів',
  currentVolunteersNumPlaceholder: 'Кількість волонтерів в організації',
  category: 'Категорія',
  description: 'опис',
  descriptionPlaceholder: 'Напишіть щось про свою подію',
  phone: 'Телефон',
  region: 'область',
  county: 'повіт',
  generalInformation: 'Загальна інформація',
  contactInformation: 'Контактна інформація',
  cover: 'Фонове зображення',
  coverDesc: 'Додайте файл (.jpg, .jpeg, .png, .gif - макс. 4 Мб) або виберіть із доступних дизайнів',
  createAccount: 'Створити аккаунт',
  registerAgreement:
    'Продовжуючи, ви погоджуєтеся з тим, що ми можемо створити для вас обліковий запис (якщо його ще не створено), і ви приймаєте наші Умови використання та Політику конфіденційності.',
  signUp: 'зареєструватися',
  changePassword: 'Змінити пароль',
  required: "Це поле є обов'язковим",
  unsupported: 'Не підтримується',
  notFound: 'Не знайдено',
  register: 'Створити аккаунт',
  forgotPassword: 'Ви забули свій пароль?',
  forgotPasswordDesc: 'Не хвилюйтеся, ми надішлемо вам інструкції щодо скидання.',
  backToLogIn: 'Назад до входу',
  enterLogin: 'Введіть логін',
  enterPassword: 'Введіть ваш пароль',
  confirmPassword: 'Підтвердьте пароль',
  enterEmail: 'Введіть Email',
  sendEmail: 'відправити лист',
  confirmSave: 'Записати',
  passwordChanged: 'Пароль успішно змінено',
  name: "Ім'я",
  address: 'Адреса',
  contactUs: 'контакт',
  volunteeringAtYourFingertips: 'Волонтерство на ваших пальцях',
  volunteeringAtYourFingertipsDesc:
    '"Hand for U" - це веб- та мобільний додаток, який допомагає благодійним фондам, волонтерам та тим, хто потребує допомоги. Хочете розширити свій фонд? Приєднуйтесь до нас!',
  systemInfo: 'Ми тестуємо додаток і постійно розвиваємося. Хочете розвивати Hand for U разом з нами? Є питання?',
  writeToUs: 'Напишіть нам',
  anyKindOfHelp: 'Ви обираєте, як допомагати"',
  anyKindOfHelpDesc: 'З нами ви створите будь-які категорії, в яких захочете допомагати!',
  healthcare: 'Медичний',
  nature: 'природи',
  charity: 'благодійність',
  elderlyPeople: 'Люди похилого віку',
  humanRights: 'Права людини',
  socialAssistance: 'Соціальна допомога',
  animals: 'Тварини',
  education: 'Освіта',
  ecology: 'Екологія',
  helpForUkraine: 'Допомога Україні',
  sportsAndTourism: 'Спорт і туризм',
  volunteeringMadeEasier: 'Волонтерство стало простіше',
  volunteeringMadeEasierDesc:
    'Веб-панель надає фондам платформу, підтримку та інструменти для управління роботою волонтерів, а також для публікації інформації в мобільному додатку.',
  createEvents: 'Створюйте події',
  createEventsDesc:
    "Нашою місією є підтримка світу, де доброта і співчуття не знають меж. Наша платформа дозволяє фондам і волонтерам об'єднуватися під час подій, які можуть бути додані фондами через веб-панель.",
  empowerVolunteers: 'Дайте волонтерам сили діяти',
  empowerVolunteersDesc:
    'Мобільний додаток надає волонтерам можливість відігравати активну роль у допомозі іншим. Волонтери можуть публікувати пропозиції допомоги та реагувати на прохання про допомогу, розміщені особами, які потребують допомоги.',
  adminDetails: 'Дані адміністратора',
  foundationDetails: 'Дані організації',
  helpLocation: 'Область довідки',
  chooseHelpLocation: 'Виберіть зону підтримки',
  actions: 'акції',
  foundationPlaceholder: 'Назва організації',
  countryPlaceholder: 'наприклад, Польща',
  postCodePlaceholder: 'наприклад, 21-345',
  cityPlaceholder: 'наприклад, Варшава',
  streetPlaceholder: 'Вулиця',
  categoryPlaceholder: 'Виберіть категорію',
  emailPlaceholder: 'наприклад, johndoe@gmail.com',
  phonePlaceholder: 'Код країни +48 або +380 і 9 цифр, наприклад +48111222333',
  helloAgain: 'З поверненням',
  loginDesc:
    'Отримайте доступ до свого облікового запису та продовжуйте робити позитивний вплив. Будь ласка, введіть свої дані нижче, щоб увійти.',
  dontHaveAnAccount: 'У вас ще немає облікового запису',
  registerHere: 'Зареєструватися тут',
  events: 'події',
  eventsDesc:
    'Ласкаво просимо на ваші події, де ваше бачення позитивних змін втілюється в життя. Тут ви знайдете список подій вашого фонду.',
  noEvents: 'У вас ще немає подій',
  noEventsDesc:
    'Ласкаво просимо до ваших подій, де ваше бачення позитивних змін втілюється в життя. Тут ви знайдете список подій вашого фонду!',
  createEvent: 'Створіть подію',
  createEventDesc: 'Готові створити нову подію? Заповніть поля нижче та виберіть локацію!',
  creator: 'Творець',
  eventStart: 'Початок заходу',
  eventStatus: 'Статус події',
  helpCategory: 'Категорія допомоги',
  created: 'Створено',
  max: 'Макс',
  volunteersNumber: 'Скільки вам потрібно волонтерів?',
  startDate: 'Дата початку',
  endDate: 'Дата закінчення',
  content: 'Зміст',
  info: 'Інформація',
  title: 'Назва',
  titlePlaceholder: 'Дивовижна назва',
  volunteers: 'Волонтери',
  ourTeam: 'Наша команда',
  clear: 'Видалити',
  addMore: 'Додати більше',
  continue: 'Продовжити',
  back: 'назад',
  setPassword: 'Встановіть пароль',
  setNewPassword: 'Введіть новий пароль',
  setNewPasswordDesc: 'Створіть новий пароль і продовжуйте свою позитивну подорож із Hand 4 You.',
  ourTeamEmptyDesc: 'Зараз у вас немає учасників! Додайте когось!',
  nothingIsHere: 'Тут нічого немає',
  nothingIsHereTeamDesc: 'Додайте кількох учасників',
  addSomeone: 'Додайте когось',
  ourEvents: 'Наші заходи',
  ourEventsEmptyDesc: 'Ось список наших заходів',
  nothingIsHereEventsDesc: 'Створіть свою першу подію',
  volunteersEmptyDesc: 'Зараз у нас немає волонтерів! Додайте когось!',
  nothingIsHereVolunteersDesc: 'Додайте волонтерів',
  vatNr: 'Ідентифікаційний номер ПДВ',
  vatNrPlaceholder: 'Введіть ідентифікаційний номер ПДВ',
  originDate: 'дата створення',
  originDatePlaceholder: 'Вкажіть дату заснування організації',
  atLeastOneOption: 'Необхідно вибрати принаймні один варіант',
  website: 'веб-сторінка',
  status: 'Статус',
  draft: 'Ескіз',
  active: 'Активний',
  finished: 'Готово',
  location: 'Місцезнаходження',
  event: 'Подія',
  nrOfVolunteersSigned: 'Кількість зареєстрованих волонтерів',
  eventCreator: 'Творець події',
  createEventSuccess: 'Щиро вітаю! Подія створена!',
  createEventError: 'Під час створення події сталася помилка!',
  editEventSuccess: 'Подія відредагована!',
  editEventError: 'Під час редагування події сталася помилка!',
  seeAll: 'Бачити всіх',
  participants: 'Учасники',
  participantsDesc: 'Список учасників, які бажають приєднатися до заходу',
  nrParticipantsNeededToFormFullTeam: 'Кількість учасників, необхідна для формування повної команди',
  numberOfTeamMembers: 'Кількість членів команди',
  inviteCoordinator: 'Запросіть координатора',
  avatar: 'Аватар',
  role: 'Роль',
  inviteCoordinatorDesc: 'Надішліть запрошення координатору приєднатися до команди',
  sendInvite: 'Надішліть запрошення',
  deleteCoordinator: 'Видалити координатора',
  deleteCoordinatorDesc: 'Цю дію не можна скасувати.',
  yesDelete: 'Так, видалити',
  profileCoordinatorDesc: 'Тут ви можете переглянути особисті дані координатора та історію його подій',
  profileVolunteerDesc: 'Тут ви можете переглянути особисті дані волонтера та історію його подій',
  personalInfo: 'Персональна інформація',
  contactInfo: 'Контактна інформація',
  locationInfo: 'Інформація про місцезнаходження',
  dateOfBirth: 'Дата народження',
  firstAddressLine: 'Перший рядок адреси',
  volunteersNeededNum: 'Необхідна кількість волонтерів',
  accountStatus: 'Статус рахунку',
  aboutMe: 'Про мене',
  languages: 'Мови',
  history: 'Історія',
  reject: 'Відхилити',
  verify: 'Перевірте',
  daysOfHelp: 'Дні допомоги',
  howDidYouFindAboutUs: 'Як ви дізналися про нас?',
  haveYouBeenAVolunteerBefore: 'Чи були ви волонтером раніше?',
  other: 'Інший',
  rejectVolunteer: 'Хочете перевірити цього волонтера?',
  rejectVolunteerDesc: 'Цю дію не можна скасувати.',
  verifyVolunteer: 'Хочете перевірити цього волонтера?',
  verifyVolunteerDesc: 'Цей волонтер буде призначено вашій організації.',
  confirm: 'Підтвердити',
  volunteerRejected: 'Волонтер відхилено',
  volunteerVerified: 'Волонтер перевірено',
  verifiedUser: 'Перевірений користувач',
  rejectedUser: 'Відхилений користувач',
  attachedFiles: 'Прикріплені файли',
  deleteVolunteer: 'Видалити волонтера',
  fileDownloaded: 'Файл завантажено',
  volunteerDeleted: 'Волонтер видалено',
  areYouSureToDeleteVolunteer: 'Ви впевнені, що хочете видалити волонтера',
  settings: 'Налаштування',
  account: 'Обліковий запис',
  notifications: 'Сповіщення',
  organization: 'Організація',
  profileDetails: 'Деталі профілю',
  uploadProfilePhoto: 'Завантажте фото профілю',
  delete: 'Видалити',
  resetPassword: 'Скинути пароль',
  currentPassword: 'Поточний пароль',
  newPassword: 'Новий пароль',
  newPasswordRepeat: 'Повторіть новий пароль',
  leaveOrganization: 'Залишити організацію',
  reason: 'Причина',
  foundProblem: 'Ви зіткнулися з проблемою',
  reportProblem: 'Повідомити про проблему',
  userManual: 'Інструкція користувача',
  newVolunteer: 'Новий волонтер',
  newEventParticipant: 'Новий учасник події',
  eventParticipantResignation: 'Вихід учасника заходу',
  volunteerLeave: 'Виїжджає волонтер',
  abuse: 'Зловживання',
  eventEnd: 'Завершення заходу',
  eventCancellation: 'Видалення події',
  systemNotifications: 'Системні сповіщення',
  newVolunteerDesc: '',
  newEventParticipantDesc: 'Отримуйте повідомлення про людей, які хочуть приєднатися до вашої організації',
  eventParticipantResignationDesc:
    'Отримуйте повідомлення про учасників вашого заходу, які відмовилися від участі в заході',
  volunteerLeaveDesc: 'Отримувати повідомлення про волонтерів, які відписалися від організації',
  abuseDesc: 'Отримуйте сповіщення про людей, про яких повідомили про зловживання програмою',
  eventEndDesc: 'Отримуйте сповіщення, коли ваша подія закінчується',
  eventCancellationDesc: 'Отримуйте сповіщення, коли адміністратор видаляє вашу подію',
  systemNotificationsDesc: 'Отримувати сповіщення про зміни та помилки, що відбуваються в системі',
  all: 'все',
  coordinators: 'Координатори',
  system: 'система',
  search: 'пошук',
  searchPlaceholder: "Ім'я, електронна пошта тощо...",
  date: 'Дата',
  logInMobileApp: 'Увійдіть у мобільний додаток H4U',
  goToMobileApp: 'Перейдіть в мобільний додаток',
  editEvent: 'Редагувати подію',
  editEventDesc: 'Відредагуйте інформацію про подію',
  mobileApplication: 'мобільний додаток',
  mobileApplicationDesc:
    'Наш додаток створений для волонтерів та людей, які потребують допомоги. Створіть обліковий запис, приєднайтеся до обраного вами фонду та простягніть руку допомоги як волонтер або візьміть її як людина, що потребує допомоги.',
  joinEvents: 'Приєднуйтеся до подій',
  publishOffers: 'Публікуйте пропозиції та прохання про допомогу',
  meetPeople: 'Знайомтесь з людьми з вашого району',
  interestedInOurProjects: 'Вас цікавлять наші проекти',
  interestedInOurProjectsDesc:
    'Вже понад 20 років ми підтримуємо компанії та установи з використанням сучасних ІТ-рішень. Ми гарантуємо, що технологія дійсно підтримує діяльність наших клієнтів і забезпечує їм справжню додану вартість.',
  peopleInNeed: 'Люди в нужді',
  peopleInNeedDesc: 'Ми допомагаємо людям, які потребують допомоги, об’єднуючи їх із волонтерами та фондами.',
  weAlreadyHave: 'У нас вже є',
  members: 'членів',
  futureEvents: 'майбутні події',
  activeEvents: 'активні події',
  finishedEvents: 'завершені події',
  noParticipantsYet: 'Поки що немає учасників',
  activationTokenAlreadyUsed: 'Токен активації вже використовується',
  rating: 'Рейтинг',
  // API responses:
  errorMessageDefault: 'помилка. спробуйте ще раз',
  invalidData: 'Введено неправильні дані',
  success: 'Успіх!',
  createSuccess: 'успішно створено',
  editSuccess: 'успішно відредаговано',
  deleteSuccess: 'успішно видалено',
  loggedIn: 'Ви успішно ввійшли',
  loggedOut: 'Ви успішно вийшли',
  loginSuccessfull: 'Вхід успішний',
  loginError: 'Помилка введених даних',
  accountExists: 'Обліковий запис із вказаною електронною адресою вже існує!',
  tokenExpired: 'Недійсний або прострочений токен',
  fileUploaded: 'Файл завантажено',
  fileDeleted: 'Файл видалено',
  awaitRegisterEmail: 'Перевірте свою поштову скриньку, щоб підтвердити реєстрацію',
  registerSucceed: 'Реєстрація пройшла успішно',
  accountActivated: 'Ваш обліковий запис активовано',
  participantsCount: 'кількість учасників',
  coordinatorInviteSent: 'Запрошення координатора надіслано',
  coordinatorDeleted: 'Координатора видалено',
  invitationSent: 'Запрошення надіслано',
  profileDetailsUpdated: 'Деталі профілю оновлено',
  passwordUpdated: 'Пароль оновлено',
  requestSent: 'Запит надіслано',
  foundationLeft: 'Фундамент був занедбаний',
  total: 'Разом',
  fileError: 'Під час завантаження файлу сталася помилка',
  forgotPasswordResponse: 'Якщо обліковий запис існує, буде надіслано електронний лист для зміни пароля',
  editOrganizationSettingsSuccess: 'Налаштування організації успішно відредаговано',
  editOrganizationSettingsError: 'Під час редагування налаштувань організації сталася помилка',
  awaitingValidation: 'Дякуємо за підтвердження електронної пошти! Ваше звернення перевіряється.',
  accountRestored: 'Ваш обліковий запис відновлено',
  // validations:
  requiredField: "Це поле є обов'язковим.",
  validEmail: 'Неправильна адреса електронної пошти',
  validPassword:
    'Пароль має містити мін. 8 символів, одна велика літера, одна мала літера, одна цифра та один спеціальний символ',
  noRows: 'Без рядків',
  nameRequired: "Ім'я обов'язкове",
  firstnameRequired: "Необхідно вказати ім'я",
  lastnameRequired: 'Прізвище обов’язкове',
  emailRequired: 'Необхідно вказати адресу електронної пошти',
  loginRequired: 'Необхідно ввійти',
  passwordRequired: 'Необхідно ввести пароль',
  passwordMatch: 'Паролі не збігаються',
  phoneInvalid: 'Неправильний номер телефону',
  invalidNip: 'Неправильний ідентифікаційний номер платника податків',
  min1Chars: 'Потрібен жест принаймні 1 символу',
  min3Chars: 'Потрібно мінімум 3 символи',
  min5Chars: 'Потрібно мінімум 5 символів',
  min200Chars: 'Потрібно мінімум 200 символів',
  max5Chars: 'Потрібно не більше 5 символів',
  max8Chars: 'Необхідно не більше 8 символів',
  max20Chars: 'Необхідно не більше 20 символів',
  max50Chars: 'Потрібно не більше 50 символів',
  max250Chars: 'Потрібно не більше 250 символів',
  max1500Chars: 'Потрібно не більше 1500 символів',
  max5000: 'Потрібно не більше 5000 символів',
  phoneNumberNineCharacters: 'Номер телефону має містити не менше 9 символів',
  invalidUrl: 'Недійсна WWW-адреса',
  invalidDateFormat: 'Неправильний формат дати',
  onlyNumbersAllowed: 'Дозволені лише цифри',
}
