// ==============================|| OVERRIDES - FORMHELPERTEXT ||============================== //

export default function FormHelperText() {
  return {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
  }
}
