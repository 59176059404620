import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { en } from './en'
import { pl } from './pl'
import { ru } from './ru'
import { uk } from './uk'

export const resources = {
  en: {
    translation: en,
  },
  pl: {
    translation: pl,
  },
  uk: {
    translation: uk,
  },
  ru: {
    translation: ru,
  },
}

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: localStorage.getItem('activeLanguage') || 'pl',
    fallbackLng: 'pl',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
