import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

function Footer() {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'space-between',
        py: 3,
        width: '100%',
        border: '1px solid #F3F4F8',
        background: '#FFF',
        boxShadow: '-4px 9px 25px -6px rgba(0, 0, 0, 0.10)',
        zIndex: '999',
      }}
    >
      <Stack direction='row' spacing='12px' sx={{ pb: 2 }}>
        <a
          style={{
            cursor: 'pointer',
            color: 'primary.main',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '12px',
            letterSpacing: '0.15px',
            textDecoration: 'none',
          }}
          href='https://docs.google.com/forms/d/1mt_izTDEtFH1y2omI5ysQQSELem-KChMbcfIsiv_HKw/'
        >
          {t('reportProblem')}
        </a>
        <a
          style={{
            cursor: 'pointer',
            color: 'primary.main',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '12px',
            letterSpacing: '0.15px',
            textDecoration: 'none',
          }}
          href='https://drive.google.com/drive/folders/1B6KU5FoU9D3Uydd4R8ippAiWN4X8T_xK'
        >
          {t('userManual')}
        </a>
      </Stack>
      <Typography
        sx={{
          color: 'grey.500',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '12px' /* 100% */,
          letterSpacing: '0.15px',
        }}
      >
        © 2023 Hand 4 U. All rights reserved.
      </Typography>
    </Box>
  )
}

export default Footer
