import { isEmpty } from 'lodash'
import { FC, Suspense, lazy } from 'react'

import { useAppSelector } from '../store/hooks'
import { selectUniversalModalOpenedList } from '../store/slices/universalModalSlice/universalModalSlice'

const UniversalModal = lazy(() => import('./UniversalModal'))

const LazyModal: FC = () => {
  const openedModalsList = useAppSelector(selectUniversalModalOpenedList)

  if (!isEmpty(openedModalsList)) {
    return (
      <Suspense>
        <UniversalModal />
      </Suspense>
    )
  }
  return null
}

export default LazyModal
