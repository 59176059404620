import { Box } from '@mui/material'
import isEmpty from 'lodash/isEmpty'
import { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import background from '../../assets/images/background.png'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { checkIfLoggedIn, selectUser } from '../../store/slices/authSlice/authSlice'

const MainLayout = () => {
  const dispatch = useAppDispatch()
  const [, setIsLoggedIn] = useState<boolean>(false)
  const user = useAppSelector(selectUser)
  const navigate = useNavigate()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const location = useLocation()

  useEffect(() => {
    if (isEmpty(user)) {
      if (
        window.location.pathname === '/' ||
        window.location.pathname.includes('registration-confirm') ||
        window.location.pathname.includes('restore-account') ||
        window.location.pathname.includes('register') ||
        window.location.pathname.includes('login') ||
        window.location.pathname.includes('reset-password')
      ) {
        return
      } else {
        dispatch(checkIfLoggedIn()).then(({ type }) => {
          type.includes('fulfilled') ? setIsLoggedIn(true) : navigate('/')
        })
      }
    } else {
      setIsLoggedIn(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundImage: `url(${background})`,
        backgroundSize: '50vw 100%',
        backgroundPosition: 'left',
        backgroundColor: 'lightgray',
        backgroundRepeat: 'no-repeat',
        borderRadius: 0,
      }}
    >
      <Header />
      <Box
        component='main'
        sx={{
          // padding: location.pathname === '/login' ? '0 300px' : 0,
          minHeight: 'calc(100vh - 234px)',
        }}
      >
        <Outlet />
      </Box>
      <Footer />
    </Box>
  )
}

export default MainLayout
