/* eslint-disable max-len */
export const ru = {
  volunteerManagementSystem: 'Система управления волонтерами',
  coordinator: 'Координатор',
  admin: 'Админ',
  home: 'домой',
  backToMainView: 'Вернуться на главную страницу',
  login: 'авторизоваться',
  signIn: 'авторизоваться',
  logout: 'выйти',
  profile: 'Профиль',
  submit: 'Отправить',
  add: 'добавить',
  save: 'сохранить',
  edit: 'редактировать',
  cancel: 'Отменить',
  alreadyhaveAccount: 'Уже есть аккаунт',
  registerFoundation: 'Зарегистрировать фонд',
  registerFoundationDesc:
    'Добро пожаловать в Hand 4 You! Мы рады видеть вас на борту. Регистрируясь у нас, вы делаете первый шаг к позитивным изменениям. Заполните данные ниже, чтобы стать частью нашего сообщества, объединяющего фонды и волонтеров.',
  email: 'Электронная почта',
  password: 'Пароль',
  repeatPassword: 'Повторите пароль',
  firstname: 'Имя',
  lastname: 'Фамилия',
  firstnameLastname: 'Имя и фамилия',
  foundationName: 'Название организации',
  country: 'страна',
  postCode: 'Почтовый индекс',
  street: 'улица',
  houseNumber: '№ дома',
  apartmentNumber: '№ квартиры',
  addressDescription: 'Детали адреса',
  city: 'Город',
  currentVolunteersNum: 'Количество волонтеров',
  currentVolunteersNumPlaceholder: 'Количество волонтеров в организации',
  category: 'Категория',
  description: 'описание',
  descriptionPlaceholder: 'Напишите что-то о вашем мероприятии',
  phone: 'Телефон',
  region: 'область',
  county: 'повит',
  generalInformation: 'Общая информация',
  contactInformation: 'Контактная информация',
  cover: 'Фотография',
  coverDesc: 'Добавьте файл (.jpg, .jpeg, .png, .gif - макс. 4 Мб) или выберите из доступных дизайнов',
  createAccount: 'Создать аккаунт',
  registerAgreement: 'Продолжая, вы соглашаетесь с тем, что мы можем',
  signUp: 'зарегистрироваться',
  changePassword: 'Изменить пароль',
  required: 'Это поле является обязательным',
  unsupported: 'Не поддерживается',
  notFound: 'Не найдено',
  register: 'Создать аккаунт',
  forgotPassword: 'Вы забыли свой пароль?',
  forgotPasswordDesc: 'Не волнуйтесь, мы вышлем вам инструкции по сбросу.',
  backToLogIn: 'Назад к входу',
  enterLogin: 'Введите логин',
  enterPassword: 'Введите ваш пароль',
  confirmPassword: 'Подтвердите пароль',
  enterEmail: 'Введите Email',
  sendEmail: 'отправить письмо',
  confirmSave: 'Записать',
  passwordChanged: 'Пароль изменен',
  name: 'Имя',
  address: 'Адрес',
  contactUs: 'контакт',
  volunteeringAtYourFingertips: 'Волонтёрство у ваших руках',
  volunteeringAtYourFingertipsDesc:
    '"Hand for U" - це веб- та мобільний додаток, який допомагає благодійним фондам, волонтерам та тим, хто потребує допомоги. Бажаєте розширити свій фонд? Приєднуйтесь до нас!',
  systemInfo:
    'Ми тестуємо додаток і постійно розвиваємося. Бажаєте приєднатися до розвитку Hand for U разом з нами? Є які-небудь питання?',
  writeToUs: 'Напишите нам',
  anyKindOfHelp: 'Вы выбираете, как помогать',
  anyKindOfHelpDesc: 'С нами вы создадите любые категории, в которых захотите помогать!',
  healthcare: 'Медицинская помощь',
  nature: 'Природа',
  charity: 'Благотворительность',
  elderlyPeople: 'Пожилые люди',
  humanRights: 'Права человека',
  socialAssistance: 'Социальная помощь',
  animals: 'Животные',
  education: 'Образование',
  ecology: 'Экология',
  helpForUkraine: 'Помощь Украине',
  sportsAndTourism: 'Спорт и туризм',
  volunteeringMadeEasier: 'Волонтёрство стало проще',
  volunteeringMadeEasierDesc:
    'Веб-панель предоставляет фондам платформу, поддержку и инструменты для управления работой волонтеров, а также для публикации информации в мобильном приложении.',
  createEvents: 'Создавайте события',
  createEventsDesc:
    'Нашей миссией является поддержка мира, где доброта и сочувствие не знают границ. Наша платформа позволяет фондам и волонтерам объединяться во время мероприятий, которые могут быть добавлены фондами через веб-панель.',
  empowerVolunteers: 'Дайте волонтерам силы действовать',
  empowerVolunteersDesc:
    'Мобильное приложение предоставляет волонтерам возможность выполнять активную роль в помощи другим. Волонтеры могут публиковать предложения помощи и реагировать на просьбы о помощи, размещенные людьми, нуждающимися в помощи.',
  adminDetails: 'Данные администратора',
  foundationDetails: 'Данные организации',
  helpLocation: 'Область помощи',
  chooseHelpLocation: 'Выберите зону поддержки',
  actions: 'Акции',
  foundationPlaceholder: 'Название организации',
  countryPlaceholder: 'например, Польша',
  postCodePlaceholder: 'например, 21-345',
  cityPlaceholder: 'например, Варшава',
  streetPlaceholder: 'Улица',
  categoryPlaceholder: 'Выберите категорию',
  emailPlaceholder: 'например, johndoe@gmail.com',
  phonePlaceholder: 'Код страны +48 или +380 и 9 цифр, например +48111222333',
  helloAgain: 'С возвращением',
  loginDesc:
    'Получите доступ к своему аккаунту и продолжайте оказывать позитивное воздействие. Пожалуйста, введите свои данные ниже, чтобы войти.',
  dontHaveAnAccount: 'У вас еще нет аккаунта',
  registerHere: 'Зарегистрироваться тут',
  events: 'События',
  eventsDesc:
    'Добро пожаловать на ваши события, где ваше видение позитивных изменений воплощается в жизнь. Здесь вы найдете список событий вашего фонда.',
  noEvents: 'У вас еще нет событий',
  noEventsDesc:
    'Добро пожаловать на ваши события, где ваше видение позитивных изменений воплощается в жизнь. Здесь вы найдете список событий вашего фонда!',
  createEvent: 'Создать событие',
  createEventDesc: 'Готовы создать новое событие? Заполните поля ниже и выберите локацию!',
  creator: 'Творец',
  eventStart: 'Начало события',
  eventStatus: 'Статус события',
  helpCategory: 'Категория помощи',
  created: 'Создано',
  max: 'Макс',
  volunteersNumber: 'Сколько вам нужно волонтеров?',
  startDate: 'Дата начала',
  endDate: 'Дата окончания',
  content: 'Содержание',
  info: 'Информация',
  title: 'Название',
  titlePlaceholder: 'Название',
  volunteers: 'Волонтеры',
  ourTeam: 'Наша команда',
  clear: 'Удалить',
  addMore: 'Добавить еще',
  continue: 'Продолжить',
  back: 'назад',
  setPassword: 'Установите пароль',
  setNewPassword: 'Введите новый пароль',
  setNewPasswordDesc: 'Создайте новый пароль и продолжайте свое позитивное путешествие с Hand 4 You.',
  ourTeamEmptyDesc: 'Сейчас у вас нет участников! Добавьте кого-то!',
  nothingIsHere: 'Тут ничего нет',
  nothingIsHereTeamDesc: 'Добавьте несколько участников',
  addSomeone: 'Добавьте кого-то',
  ourEvents: 'Наши события',
  ourEventsEmptyDesc: 'Вот список наших событий',
  nothingIsHereEventsDesc: 'Создайте свое первое событие',
  volunteersEmptyDesc: 'Сейчас у нас нет волонтеров! Добавьте кого-то!',
  nothingIsHereVolunteersDesc: 'Добавьте волонтеров',
  vatNr: 'Идентификационный номер VAT',
  vatNrPlaceholder: 'Введите идентификационный номер VAT',
  originDate: 'Дата создания',
  originDatePlaceholder: 'Укажите дату основания организации',
  atLeastOneOption: 'Необходимо выбрать хотя бы один вариант',
  website: 'веб-страница',
  status: 'Статус',
  draft: 'Эскиз',
  active: 'Активный',
  finished: 'Готово',
  location: 'Местоположение',
  event: 'Событие',
  nrOfVolunteersSigned: 'Количество зарегистрированных волонтеров',
  eventCreator: 'Творец события',
  createEventSuccess: 'Поздравляю! Событие создано!',
  createEventError: 'При создании события произошла ошибка!',
  editEventSuccess: 'Событие отредактировано!',
  editEventError: 'При редактировании события произошла ошибка!',
  seeAll: 'Посмотреть всех',
  participants: 'Участники',
  participantsDesc: 'Список участников, желающих присоединиться к событию',
  nrParticipantsNeededToFormFullTeam: 'Количество участников, необходимое для формирования полной команды',
  numberOfTeamMembers: 'Количество членов команды',
  inviteCoordinator: 'Пригласить координатора',
  avatar: 'Аватар',
  role: 'Роль',
  inviteCoordinatorDesc: 'Отправьте приглашение координатору присоединиться к команде',
  sendInvite: 'Отправить приглашение',
  deleteCoordinator: 'Удалить координатора',
  deleteCoordinatorDesc: 'Это действие нельзя отменить.',
  yesDelete: 'Да, удалить',
  profileCoordinatorDesc: 'Здесь вы можете просмотреть личные данные координатора и историю его событий',
  profileVolunteerDesc: 'Здесь вы можете просмотреть личные данные волонтера и историю его событий',
  personalInfo: 'Персональная информация',
  contactInfo: 'Контактная информация',
  locationInfo: 'Информация о местоположении',
  dateOfBirth: 'День рождения',
  firstAddressLine: 'Первая строка адреса',
  volunteersNeededNum: 'Необходимое количество волонтеров',
  accountStatus: 'Статус аккаунта',
  aboutMe: 'Обо мне',
  languages: 'Языки',
  history: 'История',
  reject: 'Отклонить',
  verify: 'Проверьте',
  daysOfHelp: 'Дни помощи',
  howDidYouFindAboutUs: 'Как вы узнали о нас?',
  haveYouBeenAVolunteerBefore: 'Были ли вы волонтером раньше?',
  other: 'Другой',
  rejectVolunteer: 'Хотите проверить этого волонтера?',
  rejectVolunteerDesc: 'Это действие нельзя отменить.',
  verifyVolunteer: 'Хотите проверить этого волонтера?',
  verifyVolunteerDesc: 'Этот волонтер будет назначен вашей организации.',
  confirm: 'Подтвердить',
  volunteerRejected: 'Волонтер отклонено',
  volunteerVerified: 'Волонтер проверено',
  verifiedUser: 'Проверенный пользователь',
  rejectedUser: 'Отклоненный пользователь',
  attachedFiles: 'Прикрепленные файлы',
  deleteVolunteer: 'Удалить волонтера',
  fileDownloaded: 'Файл загружен',
  volunteerDeleted: 'Волонтер удалено',
  areYouSureToDeleteVolunteer: 'Вы уверены, что хотите удалить волонтера',
  settings: 'Настройки',
  account: 'Аккаунт',
  notifications: 'Уведомления',
  organization: 'Организация',
  profileDetails: 'Детали профиля',
  uploadProfilePhoto: 'Загрузите фото профиля',
  delete: 'Удалить',
  resetPassword: 'Сбросить пароль',
  currentPassword: 'Текущий пароль',
  newPassword: 'Новый пароль',
  newPasswordRepeat: 'Повторите новый пароль',
  leaveOrganization: 'Покинуть организацию',
  reason: 'Причина',
  foundProblem: 'Вы столкнулись с проблемой',
  reportProblem: 'Сообщить о проблеме',
  userManual: 'Инструкция пользователя',
  newVolunteer: 'Новый волонтер',
  newEventParticipant: 'Новый участник события',
  eventParticipantResignation: 'Выход участника события',
  volunteerLeave: 'Уезжает волонтер',
  abuse: 'Злоупотребление',
  eventEnd: 'Завершение события',
  eventCancellation: 'Удаление события',
  systemNotifications: 'Системные уведомления',
  newVolunteerDesc: '',
  newEventParticipantDesc: 'Получайте уведомления о людях, которые хотят присоединиться к вашей организации',
  eventParticipantResignationDesc:
    'Получайте уведомления об участниках вашего события, которые отказались участвовать в событии',
  volunteerLeaveDesc: 'Получать уведомления о волонтерах, которые отписались от организации',
  abuseDesc: 'Получайте уведомления о людях, о которых сообщили о злоупотреблении программой',
  eventEndDesc: 'Получайте уведомления, когда ваше событие заканчивается',
  eventCancellationDesc: 'Получайте уведомления, когда администратор удаляет ваше событие',
  systemNotificationsDesc: 'Получать уведомления о изменениях и ошибках, происходящих в системе',
  all: 'все',
  coordinators: 'Координаторы',
  system: 'система',
  search: 'поиск',
  searchPlaceholder: 'Имя, электронная почта и т. д.',
  date: 'Дата',
  logInMobileApp: 'Войдите в мобильное приложение H4U',
  goToMobileApp: 'Перейдите в мобильное приложение',
  editEvent: 'Редактировать событие',
  editEventDesc: 'Измените информацию о событии',
  mobileApplication: 'Мобильное приложение',
  mobileApplicationDesc:
    'Наше приложение создано для волонтеров и нуждающихся. Создайте аккаунт, присоединитесь к выбранному вами фонду и протяните руку помощи как волонтер или как нуждающийся человек.',
  joinEvents: 'Присоединяйтесь к событиям',
  publishOffers: 'Публикуйте предложения и просьбы о помощи',
  meetPeople: 'Знакомьтесь с людьми из вашего района',
  interestedInOurProjects: 'Вас интересуют наши проекты',
  interestedInOurProjectsDesc:
    'Уже более 20 лет мы поддерживаем компании и учреждения, используя современные ИТ-решения. Мы гарантируем, что технология действительно поддерживает деятельность наших клиентов и обеспечивает им реальную добавленную стоимость.',
  peopleInNeed: 'Люди в нужде',
  peopleInNeedDesc: 'Мы помогаем людям, которым нужна помощь, объединяя их с волонтерами и фондами.',
  weAlreadyHave: 'У нас уже есть',
  members: 'членов',
  futureEvents: 'будущие события',
  activeEvents: 'активные события',
  finishedEvents: 'завершенные события',
  noParticipantsYet: 'Пока нет участников',
  activationTokenAlreadyUsed: 'Токен активации уже использован',
  rating: 'Рейтинг',
  // API responses:
  errorMessageDefault: 'ошибка. попробуйте еще раз',
  invalidData: 'Введены неверные данные',
  success: 'Успех!',
  createSuccess: 'успешно создано',
  editSuccess: 'успешно отредактировано',
  deleteSuccess: 'успешно удалено',
  loggedIn: 'Вы успешно вошли',
  loggedOut: 'Вы успешно вышли',
  loginSuccessfull: 'Вход успешный',
  loginError: 'Ошибка введенных данных',
  accountExists: 'Учетная запись с указанным адресом электронной почты уже существует!',
  tokenExpired: 'Недействительный или просроченный токен',
  fileUploaded: 'Файл загружен',
  fileDeleted: 'Файл удален',
  awaitRegisterEmail: 'Проверьте свою почтовую коробку, чтобы подтвердить регистрацию',
  registerSucceed: 'Регистрация прошла успешно',
  accountActivated: 'Ваша учетная запись активирована',
  participantsCount: 'количество участников',
  coordinatorInviteSent: 'Приглашение координатора отправлено',
  coordinatorDeleted: 'Координатор удален',
  invitationSent: 'Приглашение отправлено',
  profileDetailsUpdated: 'Детали профиля обновлены',
  passwordUpdated: 'Пароль обновлен',
  requestSent: 'Запрос отправлен',
  foundationLeft: 'Фонд покинут',
  total: 'Всего',
  fileError: 'При загрузке файла произошла ошибка',
  forgotPasswordResponse: 'Если учетная запись существует, будет отправлено письмо для смены пароля',
  editOrganizationSettingsSuccess: 'Настройки организации успешно отредактированы',
  editOrganizationSettingsError: 'При редактировании настроек организации произошла ошибка',
  awaitingValidation: 'Спасибо за подтверждение письма! Ваше заявление находится на проверке.',
  accountRestored: 'Ваша учетная запись восстановлена',
  // validations:
  requiredField: 'Это поле обязательно для заполнения.',
  validEmail: 'Неправильный адрес электронной почты',
  validPassword:
    'Пароль должен содержать минимум 8 символов, одну заглавную букву, одну строчную букву, одну цифру и один специальный символ',
  noRows: 'Без строк',
  nameRequired: 'Имя обязательно',
  firstnameRequired: 'Необходимо указать имя',
  lastnameRequired: 'Фамилия обязательна',
  emailRequired: 'Необходимо указать адрес электронной почты',
  loginRequired: 'Необходимо войти',
  passwordRequired: 'Необходимо ввести пароль',
  passwordMatch: 'Пароли не совпадают',
  phoneInvalid: 'Неправильный номер телефона',
  invalidNip: 'Неправильный идентификационный номер налогоплательщика',
  min1Chars: 'Требуется хотя бы 1 символ',
  min3Chars: 'Требуется минимум 3 символа',
  min5Chars: 'Требуется минимум 5 символов',
  min200Chars: 'Требуется минимум 200 символов',
  max5Chars: 'Требуется не более 5 символов',
  max8Chars: 'Необходимо не более 8 символов',
  max20Chars: 'Необходимо не более 20 символов',
  max50Chars: 'Требуется не более 50 символов',
  max250Chars: 'Требуется не более 250 символов',
  max1500Chars: 'Требуется не более 1500 символов',
  max5000: 'Требуется не более 5000 символов',
  phoneNumberNineCharacters: 'Номер телефона должен содержать не менее 9 символов',
  invalidUrl: 'Недействительный WWW-адрес',
  invalidDateFormat: 'Неправильный формат даты',
  onlyNumbersAllowed: 'Разрешены только цифры',
}
