import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { fetchEventDetailsReq, fetchEventsReq, postEventReq, updateEventReq } from './eventsAPI'
import { AddEventInterface, Event } from '../../../utilities/sharedInterfaces'
import { RootState } from '../../index'

interface EventsSliceState {
  eventsList: Event[]
}

const initialState: EventsSliceState = {
  eventsList: [],
}

export const fetchEvents = createAsyncThunk('list/fetchEvents', async (id: string | null | undefined) => {
  const { data = [] } = await fetchEventsReq(id)
  return data.content
})

export const postEvent = createAsyncThunk('list/postEvent', async (body: AddEventInterface) => {
  await postEventReq(body)
})

export const updateEvent = createAsyncThunk(
  'list/updateEvent',
  async ({ id, body }: { id: string | null | undefined; body: unknown }) => {
    await updateEventReq(id, body)
  },
)

export const fetchEventDetails = createAsyncThunk('list/fetchEventDetails', async (id: string | null | undefined) => {
  const { data = [] } = await fetchEventDetailsReq(id)
  return data
})

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchEvents.fulfilled, (state, action) => {
      state.eventsList = action.payload
    })
  },
})

export const selectEventsList = (state: RootState) => state.events.eventsList

export default eventsSlice.reducer
