import api from '../../../utilities/api'

export function fetchPeopleInNeedReq() {
  return api.get(`/people-in-need`, {
    headers: {
      'Accept-Language': localStorage.getItem('activeLanguage'),
    },
  })
}

export function fetchPersonInNeedProfileReq(id) {
  return api.get(`/people-in-need/${id}`, {
    headers: {
      'Accept-Language': localStorage.getItem('activeLanguage'),
    },
  })
}
