import { Box, Fade, Grow } from '@mui/material'
import { ReactNode, forwardRef } from 'react'

type PositionType = 'top-left' | 'top-right' | 'top' | 'bottom-left' | 'bottom-right' | 'bottom'
type ElType = 'grow' | 'fade' | 'collapse' | 'slide' | 'zoom'

interface TransitionsProps {
  children: ReactNode
  position?: PositionType
  type: ElType
}

const Transitions = forwardRef<HTMLInputElement, TransitionsProps>(({ children, position, type, ...others }, ref) => {
  const positionSX = {
    transformOrigin: '0 0 0',
  }

  return (
    <Box ref={ref}>
      {type === 'grow' && (
        <Grow {...others}>
          <Box sx={positionSX}>{children}</Box>
        </Grow>
      )}
      {type === 'fade' && (
        <Fade
          {...others}
          timeout={{
            appear: 0,
            enter: 300,
            exit: 150,
          }}
        >
          <Box sx={positionSX}>{children}</Box>
        </Fade>
      )}
    </Box>
  )
})

export default Transitions
