import api from '../../../utilities/api'

export function fetchVolunteersReq(id) {
  return api.get(`/organizations/${id}/volunteers`, {
    headers: {
      'Accept-Language': localStorage.getItem('activeLanguage'),
    },
  })
}

export function rejectVolunteerReq(id) {
  return api.get(`/volunteers/${id}/reject`)
}

export function verifyVolunteerReq(id) {
  return api.get(`/volunteers/${id}/verify`)
}

export function deleteVolunteerReq(id) {
  return api.get(`/volunteers/${id}/delete`)
}
