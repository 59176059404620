import { MenuItemInterface } from './index'
import i18n from '../translations/i18n'

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const modules: MenuItemInterface[] = [
  {
    id: 'peopleInNeed',
    title: i18n.t('peopleInNeed'),
    type: 'item',
    url: '/people-in-need',
  },
  {
    id: 'volunteers',
    title: i18n.t('volunteers'),
    type: 'item',
    url: '/volunteers',
  },
  {
    id: 'our-team',
    title: i18n.t('ourTeam'),
    type: 'item',
    url: '/our-team',
  },
  {
    id: 'events',
    title: i18n.t('events'),
    type: 'item',
    url: '/events',
  },
]

export default modules
