import api from '../../../utilities/api'

export function fetchEventsReq(id) {
  return api.get(`/organizations/${id}/events`, {
    headers: {
      'Accept-Language': localStorage.getItem('activeLanguage'),
    },
  })
}

export function postEventReq(body) {
  return api.post(`/events`, body)
}

export function updateEventReq(id, body) {
  return api.put(`/events/${id}`, body)
}

export function fetchEventDetailsReq(id) {
  return api.get(`/events/${id}`, {
    headers: {
      'Accept-Language': localStorage.getItem('activeLanguage'),
    },
  })
}
