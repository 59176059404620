import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import LanguageIcon from '@mui/icons-material/Language'
import { Button, Menu, MenuItem, Typography } from '@mui/material'
import { SystemStyleObject } from '@mui/system'
import { FC, useState } from 'react'

import { languages } from './helpers'
import i18n from '../../translations'

interface LanguagePickerInterface {
  sx?: SystemStyleObject
}
const LanguagePicker: FC<LanguagePickerInterface> = ({ sx }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [activeLanguage, setActiveLanguage] = useState<string>(i18n.language.toUpperCase())
  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChooseLanguage = (name, id) => {
    setActiveLanguage(name)
    i18n.changeLanguage(id)
    localStorage.setItem('activeLanguage', id)
    window.location.reload()
  }

  // Switch 'uk' code to 'UA' for displaying purposes
  const mappedLanguageItems = languages.map(el => {
    return (
      <MenuItem key={el.id} onClick={() => handleChooseLanguage(el.name, el.id)}>
        {el.id === 'uk' ? 'UA' : el.name}
      </MenuItem>
    )
  })

  return (
    <div>
      <Button
        sx={sx}
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        color='inherit'
      >
        {<LanguageIcon />} <Typography sx={{ mx: '2px' }}>{activeLanguage === 'UK' ? 'UA' : activeLanguage}</Typography>
        {<KeyboardArrowDownIcon />}
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: { width: '90px', maxWidth: '90px' },
        }}
      >
        {mappedLanguageItems}
      </Menu>
    </div>
  )
}

export default LanguagePicker
