import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { deleteVolunteerReq, fetchVolunteersReq, rejectVolunteerReq, verifyVolunteerReq } from './volunteersAPI'
import { RootState } from '../../index'

interface Volunteers {
  id: string
  imageToken: string
  name: string
  email: string
  phoneNumber: string
  roles: string[]
  helpLocations: string[]
}

interface VolunteersSliceState {
  volunteersList: Volunteers[]
}

const initialState: VolunteersSliceState = {
  volunteersList: [],
}

export const fetchVolunteers = createAsyncThunk('list/fetchVolunteers', async (id: string | null | undefined) => {
  const { data = [] } = await fetchVolunteersReq(id)
  return data.content
})

export const rejectVolunteer = createAsyncThunk('list/rejectVolunteer', async (id: string | null | undefined) => {
  const { data = [] } = await rejectVolunteerReq(id)
  return data.content
})

export const verifyVolunteer = createAsyncThunk('list/verifyVolunteer', async (id: string | null | undefined) => {
  const { data = [] } = await verifyVolunteerReq(id)
  return data.content
})

export const deleteVolunteer = createAsyncThunk('list/deleteVolunteer', async (id: string | null | undefined) => {
  const { data = [] } = await deleteVolunteerReq(id)
  return data.content
})

export const volunteersSlice = createSlice({
  name: 'volunteers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchVolunteers.fulfilled, (state, action) => {
      state.volunteersList = action.payload
    })
  },
})

export const selectVolunteersList = (state: RootState) => state.volunteers.volunteersList

export default volunteersSlice.reducer
