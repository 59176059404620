import ReactDOM from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import Loader from './components/APILoader'
import ScrollTop from './components/ScrollTop'
import UniversalModal from './modals'
import Router from './routes'
import * as serviceWorker from './serviceWorker'
import { store } from './store'
import ThemeCustomization from './themes'
import 'react-toastify/dist/ReactToastify.css'

import './index.scss'
import './translations'

export const ReactStrictMode = (
  <ReduxProvider store={store}>
    <ThemeCustomization>
      <BrowserRouter basename='/'>
        <ScrollTop>
          <Router />
        </ScrollTop>
      </BrowserRouter>
      <UniversalModal />
      <Loader />
      <ToastContainer />
    </ThemeCustomization>
  </ReduxProvider>
)

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const rootElement = ReactDOM.createRoot(document.getElementById('root')!)

export default rootElement.render(ReactStrictMode)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
