import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AnyObject } from 'yup'

import { fetchPeopleInNeedReq, fetchPersonInNeedProfileReq } from './peopleInNeedAPI'
import { RootState } from '../../index'

interface Volunteers {
  id: string
  imageToken: string
  name: string
  email: string
  phoneNumber: string
  address: string
  roles: string[]
  geoLocalization: string[]
  categoriesOfNeededHelp: AnyObject[]
}

interface VolunteersSliceState {
  peopleInNeedList: Volunteers[]
}

const initialState: VolunteersSliceState = {
  peopleInNeedList: [],
}

export const fetchPeopleInNeed = createAsyncThunk('list/fetchPeopleInNeed', async () => {
  const { data = [] } = await fetchPeopleInNeedReq()
  return data.content
})

export const fetchPersonInNeedProfile = createAsyncThunk(
  'list/fetchPersonInNeedProfile',
  async (id: string | null | undefined) => {
    const { data = [] } = await fetchPersonInNeedProfileReq(id)
    return data
  },
)

export const peopleInNeedSlice = createSlice({
  name: 'peopleInNeed',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPeopleInNeed.fulfilled, (state, action) => {
      state.peopleInNeedList = action.payload
    })
  },
})

export const selectPeopleInNeedList = (state: RootState) => state.peopleInNeed.peopleInNeedList

export default peopleInNeedSlice.reducer
