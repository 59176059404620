/* eslint-disable max-len */
export const en = {
  volunteerManagementSystem: 'Volunteer Management System',
  coordinator: 'Coordinator',
  admin: 'Admin',
  home: 'Home',
  backToMainView: 'Back to main view',
  login: 'Log in',
  signIn: 'Sign in',
  logout: 'Log out',
  profile: 'Profile',
  submit: 'Send',
  add: 'Add',
  save: 'Save',
  edit: 'Edit',
  cancel: 'Cancel',
  alreadyhaveAccount: 'Already have an account',
  registerFoundation: 'Register foundation',
  registerFoundationDesc:
    "Welcome to Hand 4 You! We're thrilled to have you on board. By registering with us, you're taking the first step towards being a force for positive change. Fill in the details below to become a part of our community, connecting foundations and volunteers to make a real difference.",
  email: 'Email Address',
  password: 'Password',
  repeatPassword: 'Repeat password',
  firstname: 'First name',
  lastname: 'Last name',
  firstnameLastname: 'First name and last name',
  foundationName: 'Foundation name',
  country: 'Country',
  postCode: 'Post code',
  city: 'City',
  street: 'Street',
  houseNumber: 'House nr',
  apartmentNumber: 'Apartment nr',
  addressDescription: 'Address details',
  currentVolunteersNum: 'Volunteers no.',
  currentVolunteersNumPlaceholder: 'Number of volunteers in the foundation',
  category: 'Category',
  description: 'Description',
  descriptionPlaceholder: 'Scribe something about your event',
  phone: 'Phone',
  region: 'Region',
  county: 'County',
  generalInformation: 'General information',
  contactInformation: 'Contact Information',
  cover: 'Cover',
  coverDesc: 'Add file (.jpg, .jpeg, .png, .gif - max 4Mb) or choose something from the available designs',
  createAccount: 'Create account',
  registerAgreement:
    'By continuing, you agree that we create an account for you (unless already created), and accept our Terms and Conditions and Privacy Policy.',
  signUp: 'Sign up',
  changePassword: 'Change password',
  required: 'This is required',
  unsupported: 'Unsupported',
  notFound: 'Page Not Found.',
  register: 'Register',
  forgotPassword: 'Forgot password',
  forgotPasswordDesc: 'Don’t worry, we’ll send you reset instructions.',
  backToLogIn: 'Back to log in',
  enterLogin: 'Enter login',
  enterPassword: 'Enter password',
  confirmPassword: 'Confirm password',
  enterEmail: 'Enter Email',
  sendEmail: 'Send email',
  confirmSave: 'Confirm',
  passwordChanged: 'Password changed',
  name: 'Name',
  address: 'Address',
  doYouNeedAnyHelp: 'Do you need any help?',
  contactUs: 'Contact us',
  volunteeringAtYourFingertips: 'Volunteering at your fingertips',
  volunteeringAtYourFingertipsDesc:
    '"Hand for U" is a web and mobile application that assists charitable foundations, volunteers, and those in need. Want to expand your foundation? Join us!',
  systemInfo:
    'We are testing the application and continuously developing. Do you want to grow Hand for U with us? Any questions?',
  writeToUs: 'Write to us',
  anyKindOfHelp: 'Any kind of help',
  anyKindOfHelpDesc: 'With us you can provide any category of help!',
  healthcare: 'Healthcare',
  nature: 'Nature',
  charity: 'Charity',
  elderlyPeople: 'Elderly people',
  humanRights: 'Human rights',
  socialAssistance: 'Social assistance',
  animals: 'Animals',
  education: 'Education',
  ecology: 'Ecology',
  helpForUkraine: 'Help for Ukraine',
  sportsAndTourism: 'Sports and tourism',
  volunteeringMadeEasier: 'Volunteering made easier',
  volunteeringMadeEasierDesc:
    'The web panel provides foundations with a platform, support, and tools to manage volunteer work, as well as to publish information in the mobile application.',
  createEvents: 'Create events',
  createEventsDesc:
    'Our mission is to support a world where kindness and compassion know no boundaries. Our platform allows foundations and volunteers to connect during events, which can be added by foundations through a web panel.',
  empowerVolunteers: 'Empower Volunteers to Act',
  empowerVolunteersDesc:
    'The mobile application gives volunteers the opportunity to play an active role in helping others. Volunteers can post offers of assistance and respond to requests for help published by individuals in need.',
  adminDetails: 'Admin details',
  foundationDetails: 'Foundation details',
  helpLocation: 'Help location',
  chooseHelpLocation: 'Choose help location',
  actions: 'Actions',
  foundationPlaceholder: 'Name your foundation',
  countryPlaceholder: 'e.g. Poland',
  postCodePlaceholder: 'e.g. 21-345',
  cityPlaceholder: 'e.g. Warsaw',
  streetPlaceholder: 'Street',
  categoryPlaceholder: 'Select category',
  emailPlaceholder: 'e.g. johndoe@gmail.com',
  phonePlaceholder: 'area code +48 or +380 & 9 digits, ex. +48111222333',
  helloAgain: 'Hello Again',
  loginDesc: 'Access your account and continue making a positive impact. Enter your credentials below to log in.',
  dontHaveAnAccount: "Don't have an account",
  registerHere: 'Click here to register',
  events: 'Events',
  eventsDesc:
    "Welcome to your events, where your vision for positive change comes to life. Here, you'll find a list of events of your foundation.",
  noEvents: 'You have no events yet',
  noEventsDesc:
    'Every event you create serves as an invitation for others to join you in your mission. Create your first event now!',
  createEvent: 'Create event',
  createEventDesc: 'Ready to create a new event? Fill in the fields below and choose the location!',
  creator: 'Creator',
  eventStart: 'Event start',
  eventStatus: 'Event status',
  helpCategory: 'Help category',
  created: 'Created',
  max: 'Max',
  volunteersNumber: 'How many volunteers do you need?',
  startDate: 'Start date',
  endDate: 'End date',
  content: 'Content',
  title: 'Title',
  titlePlaceholder: 'Amazing title',
  info: 'Info',
  volunteers: 'Volunteers',
  ourTeam: 'Our team',
  clear: 'Clear',
  addMore: 'Add more',
  continue: 'Continue',
  back: 'Back',
  setPassword: 'Set password',
  setNewPassword: 'Set new password',
  setNewPasswordDesc: 'Create a new password and continue your journey of making a positive impact through Hand 4 You.',
  ourTeamEmptyDesc: "You don't have any members now! Add someone!",
  nothingIsHere: 'Nothing is here',
  nothingIsHereTeamDesc: 'Add some members',
  addSomeone: 'Add someone',
  ourEvents: 'Our events',
  ourEventsEmptyDesc: "Here's a list of our events",
  nothingIsHereEventsDesc: 'Create your first event',
  volunteersEmptyDesc: 'We don’t have any volunteers now! Add someone!',
  nothingIsHereVolunteersDesc: 'Add some volunteers',
  vatNr: 'VAT No.',
  vatNrPlaceholder: 'Enter Foundation VAT No.',
  originDate: 'Origin Date',
  originDatePlaceholder: 'Enter year of foundation origin',
  website: 'Website',
  atLeastOneOption: 'At least one option must be selected',
  registerSucceed: 'Registration succeed',
  participantsCount: 'Nr of participants',
  status: 'Status',
  draft: 'Draft',
  active: 'Active',
  finished: 'Finished',
  location: 'Location',
  event: 'Event',
  nrOfVolunteersSigned: 'Number of volunteers signed',
  eventCreator: 'Event creator',
  createEventSuccess: 'Congratulations! Event created!',
  createEventError: 'Something went wrong while creating event!',
  editEventSuccess: 'Event edited!',
  editEventError: 'Something went wrong while editing event!',
  seeAll: 'See all',
  participants: 'Participants',
  participantsDesc: 'List of participants who want to join on event',
  nrParticipantsNeededToFormFullTeam: 'Number of participants needed to form a full team',
  numberOfTeamMembers: 'Number of team members',
  inviteCoordinator: 'Invite coordinator',
  avatar: 'Avatar',
  role: 'Role',
  inviteCoordinatorDesc: 'Send an invitation to a coordinator to join the team',
  sendInvite: 'Send invite',
  deleteCoordinator: 'Delete coordinator',
  deleteCoordinatorDesc: 'This action cannot be reverted.',
  yesDelete: 'Yes, delete',
  profileCoordinatorDesc: "Here you can check coordinator's personal information and his events history",
  profileVolunteerDesc: "Here you can check volunteer's personal information and his events history",
  personalInfo: 'Personal information',
  contactInfo: 'Contact information',
  locationInfo: 'Location information',
  dateOfBirth: 'Date of birth',
  firstAddressLine: 'First address line',
  volunteersNeededNum: 'Number of volunteers needed',
  accountStatus: 'Account status',
  aboutMe: 'About me',
  languages: 'Languages',
  history: 'History',
  reject: 'Reject',
  verify: 'Verify',
  daysOfHelp: 'Days of help',
  howDidYouFindAboutUs: 'How did you find about us?',
  haveYouBeenAVolunteerBefore: 'Have you been a volunteer before?',
  other: 'Other',
  rejectVolunteer: 'Are you sure to reject volunteer?',
  rejectVolunteerDesc: 'This action cannot be reverted.',
  verifyVolunteer: 'Are you sure to verify volunteer?',
  verifyVolunteerDesc: 'This volunteer will be assigned to your foundation.',
  confirm: 'Confirm',
  volunteerRejected: 'Volunteer rejected',
  volunteerVerified: 'Volunteer verified',
  verifiedUser: 'Verified user',
  rejectedUser: 'Rejected user',
  attachedFiles: 'Attached files',
  deleteVolunteer: 'Delete volunteer',
  fileDownloaded: 'File downloaded',
  volunteerDeleted: 'Volunteer deleted',
  areYouSureToDeleteVolunteer: 'Are you sure to delete the volunteer',
  settings: 'Settings',
  account: 'Account',
  notifications: 'Notifications',
  organization: 'Organization',
  profileDetails: 'Profile details',
  uploadProfilePhoto: 'Upload profile photo',
  delete: 'Delete',
  resetPassword: 'Reset password',
  currentPassword: 'Current password',
  newPassword: 'New password',
  newPasswordRepeat: 'Repeat new password',
  leaveOrganization: 'Leave organization',
  reason: 'Reason',
  total: 'Total',
  foundProblem: 'Found a problem',
  reportProblem: 'Report problem',
  userManual: 'User manual',
  newVolunteer: 'New volunteer',
  newEventParticipant: 'New event participant',
  eventParticipantResignation: 'Resignation of an event participant',
  volunteerLeave: 'Volunteer leaving',
  abuse: 'Abuse',
  eventEnd: 'Event end',
  eventCancellation: 'Deleting an event',
  systemNotifications: 'System notifications',
  newVolunteerDesc: 'Receive information about people wanting to join organisation',
  newEventParticipantDesc: 'Receive information about volunteers joining an event you have created',
  eventParticipantResignationDesc:
    'Receive information about your event participants, who decided to withdrawl their participation',
  volunteerLeaveDesc: "Receive information about volunteers leaving you're organisation",
  abuseDesc: 'Receive information about people being reported for their abuse within the application',
  eventEndDesc: 'Receive information the ending of your event',
  eventCancellationDesc: 'Receive information about your event being removed by the administrator',
  systemNotificationsDesc: 'Receive information changes and errors occuring within the system',
  all: 'All',
  coordinators: 'Coordinators',
  system: 'System',
  search: 'Search',
  searchPlaceholder: 'Name, email, etc...',
  date: 'Date',
  logInMobileApp: 'Log in to H4U mobile app',
  goToMobileApp: 'Go to mobile app',
  editEvent: 'Edit event',
  editEventDesc: 'Edit information about your event',
  mobileApplication: 'Mobile application',
  mobileApplicationDesc:
    'Our application is designed for both volunteers and those in need. Create an account, join a chosen foundation, and extend a helping hand as a volunteer or grasp it as someone in need.',
  joinEvents: 'Join events',
  publishOffers: 'Publish offers and requests for help',
  meetPeople: 'Meet people from your neighbourhood',
  interestedInOurProjects: 'Interested in our other products',
  interestedInOurProjectsDesc:
    "We have been supporting companies and institutions using modern IT solutions for over 20 years. We make sure that technology actually supports our clients' activities and provides them with real added value.",
  peopleInNeed: 'People in need',
  peopleInNeedDesc: 'We help people in need by connecting them with volunteers and foundations.',
  weAlreadyHave: 'We already have',
  members: 'members',
  futureEvents: 'Future events',
  activeEvents: 'Active events',
  finishedEvents: 'Finished events',
  noParticipantsYet: 'No participants yet',
  activationTokenAlreadyUsed: 'Activation token already used',
  rating: 'Rating',
  // API responses:
  errorMessageDefault: 'Something went wrong. Please try again',
  invalidData: 'Invalid data entered',
  success: 'Success!',
  createSuccess: 'Created successfully',
  editSuccess: 'Edited successfully',
  deleteSuccess: 'Deleted successfully',
  loggedIn: 'Logged in successfully',
  loggedOut: 'Logged out successfully',
  loginSuccessfull: 'Login successfull',
  loginError: 'Data entry error',
  accountExists: 'An account with provided email already exists!',
  tokenExpired: 'Token invalid or expired',
  fileUploaded: 'File uploaded',
  fileDeleted: 'File deleted',
  awaitRegisterEmail: 'Check your mailbox for register confirmation email',
  accountActivated: 'Your account is now activated',
  coordinatorInviteSent: 'Invitation has been sent',
  coordinatorDeleted: 'Coordinator deleted',
  invitationSent: 'Invitation sent',
  profileDetailsUpdated: 'Profile details updated',
  passwordUpdated: 'Password updated',
  requestSent: 'The request has been sent',
  foundationLeft: 'Foundation has been left',
  fileError: 'An error occurred while uploading the file',
  forgotPasswordResponse: 'If the account exists, a password reset email has been sent',
  editOrganizationSettingsSuccess: 'Organization settings edited successfully',
  editOrganizationSettingsError: 'Something went wrong while editing organization settings',
  awaitingVerification: 'Thank you for confirming the email! Your submission is being verified.',
  accountRestored: 'Your account has been restored',
  // validations:
  requiredField: 'This field is required.',
  validEmail: 'Must be a valid email.',
  validPassword: 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character.',
  noRows: 'No rows',
  nameRequired: 'Name is required',
  firstnameRequired: 'First Name is required',
  lastnameRequired: 'Last Name is required',
  emailRequired: 'Email address is required',
  loginRequired: 'Login is required',
  passwordRequired: 'Password is required',
  passwordMatch: "Passwords doesn't match",
  phoneInvalid: 'Phone number is invalid',
  invalidNip: 'Invalid VAT No.',
  min1Chars: 'Minimum 1 character is required',
  min3Chars: 'Minimum 3 characters is required',
  min5Chars: 'Minimum 5 characters is required',
  min200Chars: 'Minimum 200 characters is required',
  max5Chars: 'Maximum 5 characters is required',
  max8Chars: 'Maximum 8 characters is required',
  max20Chars: 'Maximum 20 characters is required',
  max50Chars: 'Maximum 50 characters is required',
  max250Chars: 'Maximum 250 characters is required',
  max1500Chars: 'Maximum 1500 characters is required',
  max5000: 'Maximum 5000 is required',
  phoneNumberNineCharacters: 'Phone number must be at least 9 characters',
  invalidUrl: 'Invalid url address',
  invalidDateFormat: 'Invalid date format',
  onlyNumbersAllowed: 'Only numbers allowed',
}
