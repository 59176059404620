import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AnyObject } from 'yup'

import {
  deleteProfilePhotoReq,
  fetchOrganizationDetailsReq,
  fetchProfileDetailsReq,
  leaveOrganizationReq,
  postProfilePhotoReq,
  updateOrganizationDetailsReq,
  updatePasswordReq,
  updateProfileDetailsReq,
} from './settingsAPI'
import { RootState } from '../../index'

interface ProfileDetails {
  name: string
  email: string
  phoneNumber: string
  country: AnyObject
  postalCode: string
  town: string
  street: string
  houseNumber: string
  apartmentNumber: string
}

interface ProfileDetailsSliceState {
  profileDetails: ProfileDetails
}

interface PasswordInterface {
  oldPassword: string
  submittedPasswords: {
    password: string
    repeatedPassword: string
  }
}

const initialState: ProfileDetailsSliceState = {
  profileDetails: {
    name: '',
    email: '',
    phoneNumber: '',
    country: {},
    postalCode: '',
    town: '',
    street: '',
    houseNumber: '',
    apartmentNumber: '',
  },
}

export const fetchProfileDetails = createAsyncThunk(
  'settings/fetchProfileDetails',
  async (id: string | null | undefined) => {
    const { data } = await fetchProfileDetailsReq(id)
    return data
  },
)

export const updateProfileDetails = createAsyncThunk(
  'settings/updateProfileDetails',
  async ({ id, body }: { id: string | null | undefined; body: unknown }) => {
    const { data } = await updateProfileDetailsReq(id, body)
    return data
  },
)

export const postProfilePhoto = createAsyncThunk('settings/postProfilePhoto', async (file: File) => {
  const { data } = await postProfilePhotoReq(file)
  return data
})

export const deleteProfilePhoto = createAsyncThunk('settings/deleteProfilePhoto', async () => {
  const { data } = await deleteProfilePhotoReq()
  return data
})

export const updatePassword = createAsyncThunk('settings/updatePassword', async (body: PasswordInterface) => {
  const { data } = await updatePasswordReq(body)
  return data
})

export const leaveOrganization = createAsyncThunk(
  'settings/leaveOrganization',
  async ({ id, body }: { id: string | null | undefined; body: unknown }) => {
    const { data } = await leaveOrganizationReq(id, body)
    return data
  },
)

export const fetchOrganizationDetails = createAsyncThunk(
  'settings/fetchOrganizationDetails',
  async (id: string | null | undefined) => {
    const { data } = await fetchOrganizationDetailsReq(id)
    return data
  },
)

export const updateOrganizationDetails = createAsyncThunk(
  'settings/updateOrganizationDetails',
  async ({ id, body }: { id: string | null | undefined; body: unknown }) => {
    const { data } = await updateOrganizationDetailsReq(id, body)
    return data
  },
)

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(updateProfileDetails.fulfilled, (state, action) => {
      state.profileDetails = action.payload
    })
  },
})

export const selectProfileDetails = (state: RootState) => state.settings.profileDetails

export default settingsSlice.reducer
