import { lazy } from 'react'

import Loadable from '../components/Loadable'
import MainLayout from '../layout/MainLayout'

const MainPage = Loadable(lazy(() => import('../screens/mainPage')))
const PeopleInNeed = Loadable(lazy(() => import('../screens/people-in-need')))
const ProfilePersonInNeed = Loadable(lazy(() => import('../screens/people-in-need/ProfilePersonInNeed')))
const Volunteers = Loadable(lazy(() => import('../screens/volunteers')))
const ProfileVolunteer = Loadable(lazy(() => import('../screens/volunteers/ProfileVolunteer')))
const OurTeam = Loadable(lazy(() => import('../screens/our-team')))
const InviteCoordinator = Loadable(lazy(() => import('../screens/our-team/InviteCoordinator')))
const ProfileCoordinator = Loadable(lazy(() => import('../screens/our-team/ProfileCoordinator')))
const RegisterCoordinator = Loadable(lazy(() => import('../screens/register-coordinator/RegisterCoordinator')))
const Events = Loadable(lazy(() => import('../screens/events')))
const CreateEvent = Loadable(lazy(() => import('../screens/events/eventsViews/CreateEvent/')))
const EditEvent = Loadable(lazy(() => import('../screens/events/eventsViews/EditEvent/')))
const EventDetails = Loadable(lazy(() => import('../screens/events/eventsViews/EventDetails/')))
const EventParticipants = Loadable(lazy(() => import('../screens/events/eventsViews/EventParticipants/')))
const Overview = Loadable(lazy(() => import('../screens/overview')))
const Settings = Loadable(lazy(() => import('../screens/settings')))
const Notifications = Loadable(lazy(() => import('../screens/notifications')))

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '',
  element: <MainLayout />,
  children: [
    {
      index: true,
      path: '',
      element: <MainPage />,
    },
    {
      index: true,
      path: '/overview',
      element: <Overview />,
    },
    {
      path: '/people-in-need',
      element: <PeopleInNeed />,
    },
    {
      path: '/people-in-need/:profileId',
      element: <ProfilePersonInNeed />,
    },
    {
      path: '/volunteers',
      element: <Volunteers />,
    },
    {
      path: '/volunteer/:profileId',
      element: <ProfileVolunteer />,
    },
    {
      path: '/our-team',
      element: <OurTeam />,
    },
    {
      path: '/invite-coordinator',
      element: <InviteCoordinator />,
    },
    {
      path: '/register-coordinator',
      element: <RegisterCoordinator />,
    },
    {
      path: '/coordinator/:profileId',
      element: <ProfileCoordinator />,
    },
    {
      path: '/events',
      element: <Events />,
    },
    {
      path: '/create-event',
      element: <CreateEvent />,
    },
    {
      path: '/edit-event/:eventId',
      element: <EditEvent />,
    },
    {
      path: 'event/:eventId',
      element: <EventDetails />,
    },
    {
      path: 'event/:eventId/participants',
      element: <EventParticipants />,
    },
    {
      path: '/settings',
      element: <Settings />,
    },
    {
      path: '/notifications',
      element: <Notifications />,
    },
  ],
}

export default MainRoutes
